import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";

import ReleasedQuestionList from "../../components/ReleasedQuestionList/ReleasedQuestionList";
import Toolbar from "../../components/Toolbar/Toolbar";
import classes from "./ReleasedListPage.module.css";

const ReleasedListPage = () => {
    const loggedIn = useSelector(state => state.loggedIn);

    return (
        loggedIn ?
        <div className={classes.ListPage}>
            <Toolbar/>
            <ReleasedQuestionList/>
        </div> : <Navigate to="/login"/>
    );
}
  
export default ReleasedListPage;
  