import React from "react";

import Privacy from "../../components/Privacy/Privacy";
import classes from "./PrivacyPage.module.css";

const PrivacyPage = () => {

  return (
    <div className={classes.PrivacyPage}>
        <Privacy/>
    </div>
  );
}

export default PrivacyPage;
  