import classes from "./AdSpace.module.css";
import { setResponsiveClassName, useInterval } from "../../../utility/utilityFunctions";
import { useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";

const AdSpace = () => {
  const screenSize = useSelector(state => state.screenSize);

  const adSpaceRef = useRef()


  useEffect(() => {
    if (adSpaceRef.current) {
      const ad = document.querySelectorAll("iframe")[0]
      if (ad) {
        adSpaceRef.current.appendChild(ad)
      }
    }
  }, [adSpaceRef.current])

  useInterval(() => {
    const ad = document.querySelectorAll("iframe")[0]
    if (ad) {
      if (!adSpaceRef.current.children.length) {
        adSpaceRef.current.appendChild(ad)
      }
    }
  }, 500)

    return (
      <div className={setResponsiveClassName(classes, screenSize, "AdSpace")} id={"adspace"} ref={adSpaceRef}>

      </div>
    );
  }
  
  export default AdSpace;
  