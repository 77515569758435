import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";

import QuestionList from "../../components/QuestionList/QuestionList";
import Toolbar from "../../components/Toolbar/Toolbar";
import classes from "./ListPage.module.css";

const ListPage = () => {
    const loggedIn = useSelector(state => state.loggedIn);

    return (
        loggedIn ?
        <div className={classes.ListPage}>
            <Toolbar/>
            <QuestionList/>
        </div> : <Navigate to="/login"/>
    );
}
  
export default ListPage;
  