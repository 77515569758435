import React from "react";
import _ from "lodash";

import GridSquare from "../GridSquare/GridSquare";

const Grid = (props) => {

    const { 
        imageDimensions,
        gridSquares,
        squareSize,
    } = props;

    const topLeftCorner = { x: 0 - imageDimensions.width / 2, y: 0 - imageDimensions.height / 2 }
    const gridOffset = props.gridPosition;
    const gridPosition = { x: topLeftCorner.x + gridOffset.x, y: topLeftCorner.y + gridOffset.y }

    return (
        <React.Fragment>
            {gridSquares.map((gridSquare, i) => (
                <GridSquare 
                    position={{x: gridPosition.x + gridSquare.x + squareSize / 2, y: gridPosition.y + gridSquare.y + squareSize / 2}}
                    key={i}
                    tileNum={i + 1}
                    interactive={true}
                    {...props}
                />
            ))}
        </React.Fragment>
            
    )
}
  
export default Grid;
  