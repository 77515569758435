import { LockClosedIcon } from '@heroicons/react/solid';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth, signInWithEmailAndPassword, setPersistence, browserLocalPersistence, browserSessionPersistence } from "firebase/auth";
import { useEffect, useState } from 'react';
import { Navigate } from "react-router-dom";
import { useInterval } from "../../utility/utilityFunctions.js";

import classes from "./Login.module.css"

export default function Login() {
  const dispatch = useDispatch();

  const auth = getAuth();

  const loggedIn = useSelector(state => state.loggedIn);
  const app = useSelector(state => state.firebaseApp);

  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const setAuthPersistenceHandler = () => {
    if (app) {
      if (rememberMe) {
        setPersistence(auth, browserLocalPersistence)
      } else if (!rememberMe) {
        setPersistence(auth, browserSessionPersistence)
      }
    }
  }

  const signInHandler = () => {
    signInWithEmailAndPassword(auth, emailInput, passwordInput)
        .then((userCredential) => {
            const user = userCredential.user;
            console.log("signed in successfully")

            dispatch({
              type: "setIsLoggedIn",
              payload: {
                loggedIn: true,
              }
            })
        }
      )
      .catch((e) => {
        console.error("Error signing in", e)
      })
    }

    // useEffect(() => {
    //   if (app) {
    //     setPersistence(auth, browserSessionPersistence);
    //   }
    // }, [])

    useInterval(() => {
      if (auth) {
        const user = auth.currentUser;
        if (user) {
          dispatch({
            type: "setIsLoggedIn",
            payload: {
              loggedIn: true
            }
          })
        }
      }
    }, 100)

    useEffect(() => {
      setAuthPersistenceHandler();
    }, [rememberMe, app])

  return (
    loggedIn ? <Navigate replace to="/list"/> :
    <>
      <div className={`flex items-center justify-center py-0 px-4 sm:px-6 lg:px-8 ${classes.Login}`}>
        <div className="max-w-md w-full space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
              alt="Workflow"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Admin Login</h2>
            
          </div>
          <div className="mt-8 space-y-6">
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                  value={emailInput}
                  onInput={(e) => {setEmailInput(e.target.value)}}
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                  value={passwordInput}
                  onInput={(e) => {setPasswordInput(e.target.value)}}
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  value={rememberMe}
                  onClick={() => { setRememberMe(!rememberMe) } }
                />
                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                  Remember me
                </label>
              </div>

              <div className="text-sm">
                <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                  Forgot your password?
                </a>
              </div>
            </div>

            <div>
              <button
                onClick={signInHandler}
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                </span>
                Sign in
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}