import React from "react";
import { setResponsiveClassName } from "../../utility/utilityFunctions";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import classes from "./Privacy.module.css";

const Privacy = () => {
    const screenSize = useSelector(state => state.screenSize);

    return (
        <div className={setResponsiveClassName(classes, screenSize, "Privacy")}>
            <Link to="/">
                <div className={classes.BackButton}>&#60; Back to game</div>
            </Link>
            <h1 className={classes.Title}>1. What Information Do We Gather About You?</h1>
                <div>
                    The information we gather about you depends on the context. 
                    By and large, it’s information about you that can personally identify you —
                    either on its own or when combined with other information.
                    The following describes the information we collect and how we obtain it.
                </div>
            <h2 className={`${classes.SubTitle} ${classes.IndentedDiv}`}>Information Collected Automatically</h2>
            <ul className={classes.DoubleIndent}>
                <li>
                    <div className={classes.Bold}>With Tracking Technologies in Your Browser and Mobile Apps:</div>
                    <div>
                        These technologies include cookies, web beacons, tags and scripts, software 
                        development kits (or SDKs) and beyond. We track and store data about how you
                        visit and use Times Services, particularly through our websites and apps. The 
                        items we log include:
                    </div>
                    <ul className={`${classes.IndentedDiv} ${classes.BottomMarginDiv}`}>
                        <li>- &nbsp;Your IP address</li>
                        <li>- &nbsp;Your location</li>
                        <li>- &nbsp;Your operating system</li>
                        <li>- &nbsp;Your browser</li>
                        <li>- &nbsp;Your browser language</li>
                        <li>- &nbsp;The URLs of any pages you visit on our sites and apps</li>
                        <li>- &nbsp;Device identifiers</li>
                        <li>- &nbsp;Advertising identifiers</li>
                        <li>- &nbsp;Other usage information</li>
                    </ul>
                </li>
                <li>
                    <div className={classes.BottomMarginDiv}>
                        We combine this data with other information we collect about you.
                        If your browser doesn’t accept our cookies, you can’t access certain 
                        parts of our websites. Because the “Do Not Track” browser-based 
                        standard signal has yet to gain widespread acceptance, we don’t 
                        currently respond to those signals. Note that, if you are a California 
                        resident, you can exercise your opt out right by turning your browser 
                        on “Do Not Track”; for more info, please see below. We however respond 
                        to the Global Privacy Control in certain territories, such as Europe and California.
                    </div>
                </li>
            </ul>

            <h1 className={classes.Title}>2. What Do We Do With The Information We Collect About You?</h1>
                <div className={classes.SubTitle}>A) We provide the Times Services</div>
                <div className={classes.IndentedDiv}>We use your information to help you use and navigate BattleNips website, such as:</div>
                <ul className={`${classes.DoubleIndent} ${classes.BottomMarginDiv}`}>
                    <li>- &nbsp;Displaying your stats</li>
                    <li>- &nbsp;Displaying Ads</li>
                </ul>    

                <div className={classes.SubTitle}>B) We Develop Products and Services, and Do Analysis.</div>
                <div className={classes.IndentedDiv}>We analyze data on our users’ usage behaviors. This helps us make business and marketing decisions.</div>
                <div className={classes.IndentedDiv}>For example, our analysis helps us determine whether our marketing is successful.</div>
                <div className={classes.IndentedDiv}>
                    Google Analytics is one of the analytics providers we use. 
                    You can find out <a className={classes.AnchorTag} href="https://policies.google.com/technologies/partner-sites">how Google Analytics uses data</a> and <a className={classes.AnchorTag} href="https://tools.google.com/dlpage/gaoptout">how to opt out of Google Analytics.</a>
                </div>

                <div className={classes.SubTitle}>C) We Allow for Personalized Advertising on the BattleNips website.</div>
                <div className={classes.IndentedDiv}>
                    We gather data and work with <a className={classes.AnchorTag} href="https://www.nytimes.com/privacy/third-party">third parties</a> to show you personalized ads on behalf of advertisers. 
                    This data comes from ad tracking technologies set by us or the third party (e.g., cookies), 
                    information from advertisers or advertising vendors (e.g., demographic data) and anything 
                    inferred from any of this information. We only use or share this information in a manner 
                    that does not reveal your identity. For example, we use Google to serve ads on BattleNips. 
                    Google uses cookies or unique device identifiers, in combination with their own data, to show 
                    you ads based on you visiting nytimes.com and other sites. You can opt out of the use of the 
                    Google cookie by visiting the <a className={classes.AnchorTag} href="https://policies.google.com/technologies/ads?hl=en">related Google privacy policy</a>.
                </div>

                <div className={classes.SubTitle}>Additional notes:</div>

                <ul className={classes.IndentedDiv}>
                    <li className={classes.BottomMarginDiv}>
                        For more about targeted advertising, and how to opt out with your specific browser and device, 
                        go to the <a className={classes.AnchorTag} href="http://optout.aboutads.info/?c=2&lang=EN">DAA Webchoices Browser Check </a> 
                        and <a className={classes.AnchorTag} href="http://optout.networkadvertising.org/?c=1">NAI Opt Out of Interest-Based Advertising</a>. You can 
                        <a className={classes.AnchorTag} href="https://youradchoices.com/appchoices"> download the AppChoices app</a> to opt out in mobile apps. You can also follow the instructions in the 
                        <a className={classes.AnchorTag} href="https://www.nytimes.com/privacy/privacy-policy#anchor-question4"> What Are Your Rights?</a> section below.
                    </li>
                    <li className={classes.BottomMarginDiv}>
                        <div>
                            We try to limit how our third-party advertising technology vendors use the information they gather from you. 
                            Many of these providers require us to enter into contracts that allow them to optimize their own services and 
                            products, or that help them create their own.
                        </div>
                        <div>
                            Essentially, these providers combine any information they gather about you through the BattleNips website 
                            with information they receive from their other clients. This helps them target ads to you on behalf of their 
                            other clients, not just us.
                        </div>
                    </li>
                    <li className={classes.BottomMarginDiv}>
                        These third parties sometimes use other services in order to serve ads; check <a className={classes.AnchorTag} href="https://www.nytimes.com/privacy/third-party">their privacy policies for more details</a>. 
                        For further information on tracking technologies and your rights and choices regarding 
                        them, see the applicable <a className={classes.AnchorTag} href="https://www.nytimes.com/subscription/dg-cookie-policy/cookie-policy.html">Cookie Policy</a>. Effective January 1, 2020, California residents have the right 
                        to instruct us not to “sell” their personal information. More information is available <a className={classes.AnchorTag} href="https://www.nytimes.com/privacy/privacy-policy#anchor-question4-sectionE">see below</a>.
                    </li>
                </ul>

                <h1 className={classes.Title}>3. With Whom Do We Share the Information We Gather?</h1>
                    <div className={classes.SubTitle}>B) With Service Providers:</div>
                    <div className={classes.IndentedDiv}>We work with service providers, as defined above, to carry out certain tasks:</div>
                    <ul className={`${classes.DoubleIndent} ${classes.BottomMarginDiv}`}>
                        <li>- &nbsp;Maintaining technology and related infrastructure</li>
                        <li>- &nbsp;Serving and targeting ads</li>
                        <li>- &nbsp;Measuring ad performance</li>
                    </ul>

                <h1 className={classes.Title}>4. What About Sensitive Personal Information?</h1>
                    <div className={classes.BottomMarginDiv}>We generally don’t want to gather any sensitive information about you. This includes:</div>
                    <ul className={`${classes.IndentedDiv} ${classes.BottomMarginDiv}`}>
                        <li>- &nbsp;Your email address</li>
                        <li>- &nbsp;Your name</li>
                        <li>- &nbsp;Your phone number</li>
                        <li>- &nbsp;Your social security number</li>
                        <li>- &nbsp;Your racial or ethnic origin</li>
                        <li>- &nbsp;Your political opinions</li>
                        <li>- &nbsp;Your religion or other beliefs</li>
                        <li>- &nbsp;Your health, biometric or genetic characteristics</li>
                        <li>- &nbsp;Any trade union membership</li>
                        <li>- &nbsp;Any criminal background</li>
                    </ul>

                <h1 className={classes.Title}>5. How Are Changes to This Privacy Policy Communicated?</h1>
                    <div>We periodically update this Privacy Policy. We will post any changes on this page by updating this policy.</div>
        </div>
    );
}

export default Privacy;
  