import React, { useCallback, useEffect, useRef, useState } from "react";
import { Graphics, Text } from "@inlet/react-pixi";
import { TextStyle } from "pixi.js"
import _ from "lodash";

const PreviewGridSquare = (props) => {

    const colors = {
        green: 0x40ee40,
        blue: 0xccccff,
        darkGrey: 0xaaaaaa,
        grey: 0xfefefe
    }

    const { 
        squareSize,
    } = props;


    const drawSquare = useCallback(g => {
        g.clear();
        
        g.beginFill(0xeeeeee);
        g.lineStyle(1, 0x000000);

        g.drawRect(
            0, 
            0, 
            props.squareSize, 
            props.squareSize
        );
    }, [props.squareSize])

    return (
            <React.Fragment>
                    <Graphics 
                        draw={drawSquare}
                        interactive={true}
                        alpha={0.5}
                        {...props}
                        tint={colors.grey}
                    >
                        <Text
                            text={props.tileNum}
                            style={
                                new TextStyle({
                                    align: "center",
                                    fontFamily: "'Oswald', sans-serif",
                                    fontSize: squareSize * 0.64,
                                })
                            }
                            anchor={0.5}
                            position={{x: squareSize / 2, y: squareSize / 2}}
                        />
                    </Graphics>
            </React.Fragment>
    )
}
  
export default PreviewGridSquare;
  