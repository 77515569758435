import classes from "./HelpModal.module.css";
import { setResponsiveClassName } from "../../../utility/utilityFunctions";
import { useSelector, useDispatch } from "react-redux";
import { IoCloseOutline } from "react-icons/io5";
import { useEffect, useRef, useState } from "react";
import { useInterval } from "../../../utility/utilityFunctions";

const HelpModal = (props) => {
    const dispatch = useDispatch();
    const screenSize = useSelector(state => state.screenSize);
    const showHelpModal = useSelector(state => state.showHelpModal);

    const [animationClasses, setAnimationClasses] = useState(classes.InitialHideModal);

    const renderCounter = useRef(0);

    const clickXHandler = () => {
        dispatch({
            type: "setShowHelpModal",
            payload: {
                showHelpModal: false
            }
        })
    }

    const adSpaceRef = useRef();

    useEffect(() => {
        if (adSpaceRef.current) {
            let ad;
            if (document.querySelectorAll("iframe")[2]) {
                ad = document.querySelectorAll("iframe")[2]
            }
            if (ad) {
            adSpaceRef.current.appendChild(ad)
            }
        }
    }, [adSpaceRef.current])

    useInterval(() => {
        let ad;
        if (document.querySelectorAll("iframe")[2]) {
            ad = document.querySelectorAll("iframe")[2]
        }
        if (ad) {
            if (!adSpaceRef.current.children.length) {
            adSpaceRef.current.appendChild(ad)
            }
        }
    }, 500)

    useEffect(() => {
        if (renderCounter.current > 0) {
            if (showHelpModal === false) {
                setAnimationClasses(`${classes.HideModal}`)
            } else if (showHelpModal === true) {
                setAnimationClasses(`${classes.ShowModal}`)
            }
        } else renderCounter.current++
    }, [showHelpModal])

    return (
        <div className={`${setResponsiveClassName(classes, screenSize, "HelpModal")} ${animationClasses}`} style={screenSize === "mobile" ? {height: window.innerHeight} : {}}>
        <div className={classes.ExitX} onClick={clickXHandler}>
            <IoCloseOutline
                color={"white"}
                size={"32px"}
            />
        </div>
        <div className={classes.HelpModalTitle}>HOW TO PLAY</div>
            <div className={classes.InstructionList}>
                <div className={classes.Instruction}>Welcome to <span className={classes.BoldText}>BattleNips!</span></div>
                <div className={classes.Instruction}>Your objective is to find both nipples within <span className={classes.BoldText}>SIX (6) ATTEMPTS.</span></div>
                <div className={classes.Instruction}>Each attempt is complete once you select <span className={classes.BoldText}>TWO (2)</span> numbered boxes. Once selected, the boxes will be highlighted.</div>
                <div className={classes.Instruction}>After each attempt, the color of the boxes will change to show you a <span className={classes.BoldText}>HIT (<span className={classes.GreenText}>GREEN</span>)</span> or a <span className={classes.BoldText}>MISS (<span className={classes.GreyText}>GREY</span>)</span>.</div>
            </div>
            <div className={`${classes.Instruction} ${classes.NewChallengeEveryDay}`}>A new <span className={classes.BoldText}>BattleNips</span> challenge will be available each day!</div>
            <div className={classes.HelpAd} ref={adSpaceRef}>

            </div>
      </div>
    );
  }
  
  export default HelpModal;
  