import React, { useCallback, useEffect, useRef, useState } from "react";
import _ from "lodash";

import PreviewGridSquare from "../PreviewGridSquare/PreviewGridSquare";

const PreviewGrid = (props) => {
    const { 
        gridDimensions,
        imageDimensions,
        gridSquares,
        squareSize,
    } = props;

    const topLeftCorner = { x: 0 - imageDimensions.width / 2, y: 0 - imageDimensions.height / 2 }
    const gridOffset = props.gridPosition;
    const gridPosition = { x: topLeftCorner.x + gridOffset.x, y: topLeftCorner.y + gridOffset.y }

    return (
        <React.Fragment>
            
            {gridSquares.map((gridSquare, i) => (
                <PreviewGridSquare 
                    position={{x: gridPosition.x + gridSquare.x, y: gridPosition.y + gridSquare.y}}
                    key={i}
                    tileNum={i + 1}
                    {...props}
                />
            ))}
        </React.Fragment>
            
    )
}
  
export default PreviewGrid;
  