import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import QuestionItem from './QuestionItem/QuestionItem';

import classes from "./QuestionList.module.css";

export default function QuestionList() {
  const dispatch = useDispatch();

  const unreleasedQuestions = useSelector(state => state.unreleasedQuestions);

  useEffect(() => {
    console.log("useEffect from list")
    dispatch({
      type: "setNeedUnreleasedQuestions",
      payload: {
        needUnreleasedQuestions: true
      }
    })
  }, [])

    return (
        <div className={classes.QuestionListContainer}>
            <div className={`bg-white shadow overflow-hidden sm:rounded-md ${classes.QuestionList}`}>
                <ul role="list" className="divide-y divide-gray-200">
                    {unreleasedQuestions.map((question, i) => (
                      <QuestionItem question={question} key={i}/>
                    ))}
                </ul>
            </div>
                <Link className={classes.NewQuestion} to="/new">+</Link>
        </div>
  )
}