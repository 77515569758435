import classes from "./HeaderItem.module.css";
import { setResponsiveClassName } from "../../../../../utility/utilityFunctions";
import { useDispatch, useSelector } from "react-redux";
import { FiMenu } from "react-icons/fi";
import { IoHelpCircleOutline } from "react-icons/io5";
import { IoStatsChartSharp } from "react-icons/io5";
import { IoShareSocialOutline } from "react-icons/io5";

const HeaderItem = (props) => {
  const dispatch = useDispatch();

  const screenSize = useSelector(state => state.screenSize);
  const showStatisticsModal = useSelector(state => state.showStatisticsModal);
  const showHelpModal = useSelector(state => state.showHelpModal);
  const turnsGuessedCorrect = useSelector(state => state.turnsGuessedCorrect);
  const isGameOver = useSelector(state => state.isGameOver);
  const showSidebar = useSelector(state => state.showSidebar);

  const helpClickedHandler = () => {
    dispatch({
      type: "setShowHelpModal",
      payload: {
          showHelpModal: !showHelpModal
      }
    })
  }

  const statisticsClickedHandler = () => {
      dispatch({
          type: "setShowStatisticsModal",
          payload: {
              showStatisticsModal: !showStatisticsModal
          }
      })
    }

  const sideDrawerClickedHandler = () => {
    dispatch({
      type: "setShowSidebar",
      payload: {
        showSidebar: !showSidebar
      }
    })
  }

  const getGuessSquares = () => {
    let squareString = "";
    for (let i = 1; i < 7; i++) {
      if (i === turnsGuessedCorrect[0] && i === turnsGuessedCorrect[1]) {
        squareString = squareString + "(  o  Y  o  )\n"
        return squareString;
      } else if (i === turnsGuessedCorrect[0]) {
        squareString = squareString + "⬛🟩⬛⬛⬛\n";
      } else if (i === turnsGuessedCorrect[1]) {
        squareString = squareString + "(  o  Y  o  )\n"
        return squareString;
      } else {
        squareString = squareString + "⬛⬛⬛⬛⬛\n";
      }
    }
    return squareString;
  }

  const shareClickedHandler = () => {
    const date = new Date(Date.now())
    const formattedDate = `${date.getMonth() + 1}/${date.getDate()}`



    const shareData = {
      title: "BattleNips",
      text: `BattleNips ${formattedDate} - I challenge you to a game of BattleNips! Can you beat my score? \n${isGameOver ? getGuessSquares() : ""}`,
      url: "https://battlenips.app/",
    }

    try {
      navigator.share(shareData)
    } catch (e) {
      console.error("Error sharing question", e)
    }
  }

    return (
      <div className={setResponsiveClassName(classes, screenSize, "HeaderItem")}>
        {
            props.itemType === "sidedrawer" ? <FiMenu color="white" size="25.6px" onClick={sideDrawerClickedHandler}/> :
            props.itemType === "help" ? <IoHelpCircleOutline color="white" size="25.6px" onClick={helpClickedHandler}/> :
            props.itemType === "title" ? <div className={classes.Title}>BattleNips</div> :
            props.itemType === "statistics" ? <IoStatsChartSharp color="white" size="25.6px" onClick={statisticsClickedHandler}/> : 
            props.itemType === "share" ? <IoShareSocialOutline color="white" size="25.6px" onClick={shareClickedHandler}/> : null
        }
      </div>
    );
  }
  
  export default HeaderItem;
  