import React, { useState, useEffect } from "react";
import { Stage, Container } from "@inlet/react-pixi";
import { useDispatch, useSelector } from "react-redux";
import PreviewBackgroundImage from "./PixiComponents/PreviewBackgroundImage/PreviewBackgroundImage";
import PreviewGrid from "./PixiComponents/PreviewGrid/PreviewGrid";
import { useInterval } from "../../../utility/utilityFunctions";

import classes from "./NewQuestionPreview.module.css";

const NewQuestionPreview = () => {
    const dispatch = useDispatch();

    const gridDimensions = useSelector(state => state.newQuestionData.gridDimensions);
    const gridPosition = useSelector(state => state.newQuestionData.gridPosition);
    const squareSize = useSelector(state => state.newQuestionData.squareSize);
    const imgURL = useSelector(state => state.newQuestionData.imgURL);
    const imageUpdating = useSelector(state => state.imageUpdating);
    const editQuestion = useSelector(state => state.editQuestion);
    const [imageScale, setImageScale] = useState(1);
    const [imageDimensions, setImageDimensions] = useState({ height: 100, width: 100 });
    const stageHeight = 433;
    const stageWidth = 343;

    const getGridSquareCoords = () => {
        let gridSquareCoords = []
        for (let b = 0; b < gridDimensions.b; b++) {
            for (let a = 0; a < gridDimensions.a; a++) {
                gridSquareCoords.push({x: a * squareSize, y: b * squareSize})
            }
        }
        return gridSquareCoords;
    }

    const [gridSquares, setGridSquares] = useState(getGridSquareCoords());

    const getResizedImageScale = () => {
        let requiredScale;

        // (stageHeight - imageDimensions.height * imageScale) / 2

        if (imageDimensions.width - stageWidth > imageDimensions.height - stageHeight) {
            requiredScale = stageWidth / imageDimensions.width;
        } else if (imageDimensions.width - stageWidth <= imageDimensions.height - stageHeight) {
            requiredScale = stageHeight / imageDimensions.height;
        } else { 
            requiredScale = 1;
        }

        return requiredScale;
    }

    useEffect(() => {
        if (!editQuestion.isEditing) {
            dispatch({
                type: "setNewImgURL",
                payload: {
                    imgURL: "https://i.imgur.com/eId1Rn5.jpg"
                }
            })
        }
    }, [])

    useEffect(() => {
        let img = new Image;
        img.src = imgURL;
        img.onload = () => {
            setImageDimensions({ height: img.height, width: img.width });
            setImageScale(getResizedImageScale());
        }
    }, [imgURL])

    useEffect(() => {
        setGridSquares(getGridSquareCoords())
    }, [gridDimensions])

    useInterval(() => {
        setImageScale(getResizedImageScale());
    }, 100);

    useEffect(() => {
        if (imageUpdating) {
            dispatch({
                type: "setImageUpdating",
                payload: {
                    imageUpdating: false
                }
            })
        }
    }, [imageUpdating])

    return (
        <div className={classes.NewQuestionPreview}>
            <div className={classes.GameContainer}>
                <Stage
                    width={stageWidth} 
                    height={stageHeight}
                    options={{
                        backgroundAlpha: 0,
                        antialias: true,
                        interactive: false
                    }}
                    style={{touchAction: "auto"}}>
                        <Container scale={imageScale}>
                            <PreviewBackgroundImage
                                position={{ x: stageWidth / 2 / imageScale, y: stageHeight / 2 / imageScale }}
                                anchor={0.5}
                                image={imgURL}
                            >
                                <PreviewGrid
                                    requiredScale={imageScale}
                                    anchor={0.5}
                                    stageWidth={stageWidth}
                                    stageHeight={stageHeight}
                                    gridDimensions={gridDimensions}
                                    gridPosition={gridPosition}
                                    squareSize={squareSize}
                                    imageDimensions={imageDimensions}
                                    gridSquares={gridSquares}
                            />
                            </PreviewBackgroundImage>
                            
                        </Container>
            </Stage>
            </div>
        </div>
    );
}
  
export default NewQuestionPreview;
  