import { useEffect, useState } from "react";
import classes from "./StatusNum.module.css";
import { setResponsiveClassName } from "../../../../utility/utilityFunctions";
import { useSelector } from "react-redux";

const StatusNum = (props) => {
  const screenSize = useSelector(state => state.screenSize);
  const wrongTiles = useSelector(state => state.wrongTiles);
  const partialGuess = useSelector(state => state.partialGuess);
  const guessedTiles = useSelector(state => state.guessedTiles);
  const guessesLeft = useSelector(state => state.guessesLeft);
  const correctTiles = useSelector(state => state.questionData.correctTiles);
  const gameWon = useSelector(state => state.gameWon);

  const [numberColor, setNumberColor] = useState("");

  useEffect(() => {
      if (gameWon && !props.isGuessNumber) {
        setNumberColor(classes.CorrectGuess)
      } else if (partialGuess[0] === props.number) {
        setNumberColor(classes.CorrectGuess)
      } else if (correctTiles.includes(Number(props.number)) && !guessedTiles.includes(Number(props.number)) ) {
        setNumberColor(classes.CorrectGuess)
      } else setNumberColor("")

  }, [wrongTiles, partialGuess, guessedTiles, gameWon, guessesLeft])

    return (
      <div className={`${setResponsiveClassName(classes, screenSize, "StatusNum")} ${props.number ? numberColor : ""}`}>
        {props.number ? props.number : "-"}
      </div>
    );
  }
  
  export default StatusNum;
  