import { useDispatch, useSelector } from "react-redux";
import { useInterval } from "../../../utility/utilityFunctions";

const NextBattleTimeHandler = (props) => {
    const dispatch = useDispatch();

    const msTimeLeft = useSelector(state => state.questionData.msTimeLeft);
    const timerOn = useSelector(state => state.timerOn);
    
    useInterval(() => {
        dispatch({
          type: "setMsTimeLeft",
          payload: {
            msTimeLeft: msTimeLeft - 1000
          }
        })
        if (msTimeLeft < 0) { 
          window.location.reload()
        }
      }, timerOn ? 1000 : 99999999999)

    return null
}

export default NextBattleTimeHandler;