import React from "react";
import GameContainer from "../../components/GameContainer/GameContainer";
import { setResponsiveClassName, useInterval } from "../../utility/utilityFunctions";
import { useSelector } from "react-redux";

import classes from "./GamePage.module.css";

const GamePage = () => {
  const screenSize = useSelector(state => state.screenSize)

  useInterval(() => {
    window.scrollTo(0, 0)
  }, 500)

  return (
    <div 
      className={setResponsiveClassName(classes, screenSize, "GamePage")}
      style={screenSize === "mobile" ? {height: window.innerHeight} : {}}>
      <GameContainer/>
    </div>
  );
}

export default GamePage;
  