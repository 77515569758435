import { useState, useEffect, useRef, useCallback } from "react";
import classes from "./GameScreen.module.css";
import {
  setResponsiveClassName,
  useInterval,
} from "../../../utility/utilityFunctions";
import { useDispatch, useSelector } from "react-redux";
import { Container, Stage } from "@inlet/react-pixi";
import DummyImage from "../../../assets/images/battlenipsDummyImage.png";

import BackgroundImage from "./PixiComponents/BackgroundImage/BackgroundImage";
import Grid from "./PixiComponents/Grid/Grid";

const GameScreen = (props) => {
  const dispatch = useDispatch();

  const { guessedTiles, setGuessedTiles } = props;

  const getGridSquareCoords = () => {
    let gridSquareCoords = [];
    for (let b = 0; b < gridDimensions.b; b++) {
      for (let a = 0; a < gridDimensions.a; a++) {
        gridSquareCoords.push({ x: a * squareSize, y: b * squareSize });
      }
    }
    return gridSquareCoords;
  };

  const screenSize = useSelector((state) => state.screenSize);
  const screenDimensions = useSelector((state) => state.screenDimensions);
  const gridDimensions = useSelector(
    (state) => state.questionData.gridDimensions
  );
  const gridPosition = useSelector((state) => state.questionData.gridPosition);
  const squareSize = useSelector((state) => state.questionData.squareSize);
  const imgURL = useSelector((state) => state.questionData.imgURL);
  const questionLoaded = useSelector(
    (state) => state.questionData.questionLoaded
  );
  const correctTiles = useSelector((state) => state.questionData.correctTiles);
  const turnsGuessedCorrect = useSelector((state) => state.turnsGuessedCorrect);
  const gameWon = useSelector((state) => state.gameWon);
  const guessesLeft = useSelector((state) => state.guessesLeft);
  const partialGuess = useSelector((state) => state.partialGuess);
  const wrongTiles = useSelector((state) => state.wrongTiles);
  const isGameOver = useSelector((state) => state.isGameOver);
  const userData = useSelector((state) => state.userData);
  const questionData = useSelector((state) => state.questionData);
  const reduxGuessedTiles = useSelector((state) => state.guessedTiles);
  const barHeight = useSelector((state) => state.barHeight);
  const [gridSquares, setGridSquares] = useState(getGridSquareCoords());
  const [imageScale, setImageScale] = useState(1);
  const [imageDimensions, setImageDimensions] = useState({
    height: 500,
    width: 500,
  });
  const [gridAlpha, setGridAlpha] = useState(1);
  const [screenAlpha, setScreenAlpha] = useState(0);

  const gameScreenRef = useRef();

  const getStageWidth = () => {
    const gameScreenMargins = 32;
    const gameScreenDesktopWidth = 480;

    if (screenSize === "mobile") {
      return window.innerWidth - gameScreenMargins;
    } else if (screenSize === "desktop") {
      return gameScreenDesktopWidth - gameScreenMargins;
    } else return 448;
  };

  const getStageHeight = () => {
    const gameScreenDesktopSize = 412;

    if (screenSize === "mobile" && gameScreenRef.current) {
      return gameScreenRef.current.clientHeight;
    } else if (screenSize === "desktop") {
      return gameScreenDesktopSize;
    }
  };

  const [stageWidth, setStageWidth] = useState(getStageWidth());
  const [stageHeight, setStageHeight] = useState(getStageHeight());

  const updateStageSize = () => {
    setStageWidth(getStageWidth());
    setStageHeight(getStageHeight());
  };

  const getResizedImageScale = () => {
    let requiredScale;

    // (stageHeight - imageDimensions.height * imageScale) / 2
    if (requiredScale > 100) {
        requiredScale = imageScale;
        return requiredScale;
    }

    if (
      imageDimensions.width - stageWidth >
      imageDimensions.height - stageHeight
    ) {
      requiredScale = stageWidth / imageDimensions.width;
    } else if (
      imageDimensions.width - stageWidth <=
      imageDimensions.height - stageHeight
    ) {
      requiredScale = stageHeight / imageDimensions.height;
    } else {
      requiredScale = 1;
    }

    return requiredScale;
  };

  let img = new Image();
  img.src = imgURL;

  const getPrevGameDataString = () => {
    let dataString = "";
    if (correctTiles.includes(guessedTiles[0])) {
      dataString = `${dataString}${guessedTiles[0].toString()}-`;
    } else {
      dataString = `${dataString}${0}-`;
    }
    if (correctTiles.includes(guessedTiles[1])) {
      dataString = `${dataString}${guessedTiles[1].toString()}-`;
    } else {
      dataString = `${dataString}${0}-`;
    }
    dataString = `${dataString}${guessesLeft}`;

    return dataString;
  };

  useEffect(() => {
    img.onload = () => {
      if (img.height !== 0 && img.width !== 0) {
        setImageDimensions({ height: img.height, width: img.width });
      }

      setImageScale(getResizedImageScale());
    };
  }, [screenDimensions, imageScale]);

  // this useInterval might be causing lag
  useInterval(() => {
    if (
      imageDimensions.height !== img.height ||
      imageDimensions.width !== img.width
    ) {
      if (img.height !== 0 && img.width !== 0) {
        setImageDimensions({ height: img.height, width: img.width });
      }
    }
    const resizedScale = getResizedImageScale()
    if (imageScale !== resizedScale) {
      setImageScale(resizedScale);
    }
  }, 80);

  useInterval(() => {
    updateStageSize();
  }, 500)

  useEffect(() => {
    setImageScale(getResizedImageScale());
  }, [stageWidth, stageHeight]);

  useEffect(() => {
    updateStageSize();
  }, [screenDimensions, screenSize]);

  useEffect(() => {
    setGridSquares(getGridSquareCoords());
  }, [gridDimensions, stageWidth, stageHeight]);

  useEffect(() => {
    dispatch({
      type: "setGuessedTiles",
      payload: {
        guessedTiles: guessedTiles,
      },
    });
  }, [guessedTiles]);

  useEffect(() => {
    if (userData.questionsCompleted.includes(questionData.questionID)) {
      dispatch({
        type: "setIsGameOver",
        payload: {
          isGameOver: true,
        },
      });
    }
  }, [userData.questionsCompleted, questionData.questionID]);

  useEffect(() => {
    if (isGameOver) {
      setGridAlpha(0.25);

      if (turnsGuessedCorrect[0] || wrongTiles[0]) {
        localStorage.setItem("prevGameData", getPrevGameDataString());
      } else {
        const prevGameDataString = localStorage.getItem("prevGameData");
        const prevGameDataArray = prevGameDataString.split("-");
        console.log(prevGameDataArray);
        props.setGuessedTiles(reduxGuessedTiles);

        if (prevGameDataArray[0] !== "0" && prevGameDataArray[1] !== 0) {
          dispatch({
            type: "setGameWon",
            payload: {
              gameWon: true,
            },
          });
        } else if (prevGameDataArray[0] !== "0") {
          dispatch({
            type: "setPartialGuess",
            payload: {
              partialGuess: [Number(prevGameDataArray[0])],
            },
          });
        }

        dispatch({
          type: "setGuessedTiles",
          payload: {
            guessedTiles:
              prevGameDataArray[0] && prevGameDataArray[1]
                ? [Number(prevGameDataArray[0]), Number(prevGameDataArray[1])]
                : prevGameDataArray[0]
                ? [Number(prevGameDataArray[0]), null]
                : [Number(prevGameDataArray[1]), null],
          },
        });
        dispatch({
          type: "setGuessesLeft",
          payload: {
            guessesLeft:
              prevGameDataArray[2] || prevGameDataArray === 0
                ? Number(prevGameDataArray[2])
                : 6,
          },
        });
      }
    }
  }, [isGameOver]);

  useEffect(() => {
    if (questionLoaded && img.complete) {
      setTimeout(() => {
        setScreenAlpha(1)
      }, 10);
    }
  }, [questionLoaded, img.complete])

  return (
    <div
      className={setResponsiveClassName(classes, screenSize, "GameScreen")}
      ref={gameScreenRef}
    >
      {questionLoaded ? (
        <Stage
          width={stageWidth}
          height={stageHeight}
          options={{
            backgroundAlpha: 0,
            antialias: true,
            interactive: true,
          }}
          style={{ touchAction: "auto" }}
        >
          <Container scale={imageScale}>
            <BackgroundImage
              position={{
                x: stageWidth / 2 / imageScale,
                y: stageHeight / 2 / imageScale,
              }}
              anchor={0.5}
              alpha={screenAlpha}
              image={
                imageDimensions.height !== 100
                  ? imgURL
                  : "https://i.imgur.com/fDEO03m.jpg"
              }
            >
              <Grid
                guessedTiles={guessedTiles}
                setGuessedTiles={setGuessedTiles}
                correctTiles={correctTiles}
                wrongTiles={wrongTiles}
                requiredScale={imageScale}
                gameWon={gameWon}
                guessesLeft={guessesLeft}
                partialGuess={partialGuess}
                anchor={0.5}
                alpha={gridAlpha}
                stageWidth={stageWidth}
                stageHeight={stageHeight}
                gridDimensions={gridDimensions}
                gridPosition={gridPosition}
                squareSize={squareSize}
                imageDimensions={imageDimensions}
                gridSquares={gridSquares}
                isGameOver={isGameOver}
              />
            </BackgroundImage>
          </Container>
        </Stage>
      ) : null}
    </div>
  );
};

export default GameScreen;