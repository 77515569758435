import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

const ScreenSizeProvider = (props) => {
    const dispatch = useDispatch();

    const updateScreenSize = (screenSize) => {
        dispatch({
            type: "setScreenSize",
            payload: {
                screenSize: screenSize
            }
        })
    };

    const updateScreenDimensions = (screenDimensions) => {
        dispatch({
            type: "setScreenDimensions",
            payload: {
                screenDimensions: { ...screenDimensions }
            }
        })
    };

    const desktopBreakpoint = 1150;
    const mobileBreakpoint = 500;

    useEffect(() => {
        setTimeout(() => {  // setTimeout because window.innerWidth returns wrong value without it
            updateScreenDimensions({ width: window.innerWidth, height: window.innerHeight })    
            
                if (window.innerWidth > mobileBreakpoint) 
            {
                updateScreenSize("desktop")
            }
                if (window.innerWidth < mobileBreakpoint) 
            {
                updateScreenSize("mobile")
            } 
        }, 1);
        window.addEventListener("resize", () => {
            updateScreenDimensions({ width: window.innerWidth, height: window.innerHeight })
                if (window.innerWidth < mobileBreakpoint) 
            {
                updateScreenSize("mobile")
            }  else if (window.innerWidth > mobileBreakpoint) {
                updateScreenSize("desktop")
            }
        });
        return () => window.removeEventListener("resize", () => {
            updateScreenDimensions({ width: window.innerWidth, height: window.innerHeight })
                if (window.innerWidth < mobileBreakpoint) 
            {
                updateScreenSize("mobile")
            } else if (window.innerWidth > mobileBreakpoint) {
                updateScreenSize("desktop")
            }
        });
    });
    return null;
}

export default ScreenSizeProvider;