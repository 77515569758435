import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import classes from "./SidebarInput.module.css";

const SidebarInput = (props) => {
    const dispatch = useDispatch();
    const editQuestion = useSelector(state => state.editQuestion);
    const [value1, setValue1] = useState(props.type === "gridDimensions" ? 10 : props.type === "squareSize" ? 25 : props.type === "gridPosition" ? 0 : 1);
    const [value2, setValue2] = useState(props.type === "gridDimensions" ? 10 : props.type === "squareSize" ? 25 : props.type === "gridPosition" ? 0 : 2);

    let dispatchData;
    if (props.type === "gridPosition") {
        dispatchData = () => {
            dispatch({
                type: "setNewGridPosition",
                payload: {
                    gridPosition: { x: value1, y: value2 }
                }
            })
        }
    } else if (props.type === "gridDimensions") {
        dispatchData = () => {
            dispatch({
                type: "setNewGridDimensions",
                payload: {
                    gridDimensions: { a: value1, b: value2 }
                }
            })
        }
    } if (props.type === "squareSize") {
        dispatchData = () => {
            dispatch({
                type: "setNewSquareSize",
                payload: {
                    squareSize: value1
                }
            })
        }
    } else if (props.type === "correctTiles") {
        dispatchData = () => {
            dispatch({
                type: "setNewCorrectTiles",
                payload: {
                    correctTiles: [value1, value2]
                }
            })
        }
    }

    useEffect(() => {
        if (value1 || value2) {
            dispatchData();
        }
    }, [value1, value2])
    
    useEffect(() => {
        if (editQuestion.isEditing) {
            if (props.type === "gridPosition") {
                setValue1(editQuestion.questionData.gridPosition.x)
                setValue2(editQuestion.questionData.gridPosition.y)
            } else if (props.type === "gridDimensions") {
                setValue1(editQuestion.questionData.gridDimensions.a)
                setValue2(editQuestion.questionData.gridDimensions.b)
            } else if (props.type === "squareSize") {
                setValue1(editQuestion.questionData.squareSize)
            } else if (props.type === "correctTiles") {
                setValue1(editQuestion.questionData.correctTiles[0])
                setValue2(editQuestion.questionData.correctTiles[1])
            }
        }
    }, [editQuestion.isEditing])

    return (
        !props.doubleInput ?
        <div className={classes.SidebarInputContainer}>
            <div className={classes.SidebarInputName}>
                {props.name}
            </div>
            <input 
                className={classes.SidebarInputFieldSingle} 
                type="text" 
                onInput={(e) => {
                    if (!isNaN(e.target.value)) {
                        setValue1(Number(e.target.value))
                    }
                }} 
                value={value1}/>
        </div> :

        <div className={classes.SidebarInputContainerDouble}>
            <div className={classes.SidebarInputName}>
                {props.name}
            </div>
            <div className={classes.DoubleInputWrapper}>
                <div className={classes.FieldName}>
                    {props.fieldNames[0]}
                </div>
                <input 
                    className={classes.SidebarInputFieldDouble} 
                    type="text" 
                    onInput={(e) => { 
                        if (!isNaN(e.target.value)) { 
                            if (props.type === "gridDimensions") {
                                if (Number(e.target.value <= 30)) {
                                    setValue1(Number(e.target.value))
                                }
                            } else {
                                setValue1(Number(e.target.value)) 
                            }
                        }
                    }} 
                    value={value1}/>
            </div>
            <div className={classes.DoubleInputWrapper}>
                <div className={classes.FieldName}>
                    {props.fieldNames[1]}
                </div>
                <input 
                    className={classes.SidebarInputFieldDouble} 
                    type="text" 
                    onInput={(e) => {
                        if (!isNaN(e.target.value)) { 
                            if (props.type === "gridDimensions") {
                                if (Number(e.target.value <= 30)) {
                                    setValue2(Number(e.target.value))
                                }
                            } else {
                                setValue2(Number(e.target.value)) 
                            }
                        }
                    }} 
                    value={value2}/>
            </div>
            
        </div>
    );
}
  
export default SidebarInput;
  