import { useState } from "react";
import { useSelector } from "react-redux";

import classes from "./NextBattleTimer.module.css";


const NextBattleTimer = (props) => {
    const msTimeLeft = useSelector(state => state.questionData.msTimeLeft);

    const msToHMS = (duration) => {
        let milliseconds = parseInt((duration % 1000) / 100),
        seconds = parseInt((duration / 1000) % 60),
        minutes = parseInt((duration / (1000 * 60)) % 60),
        hours = parseInt((duration / (1000 * 60 * 60)) % 24);

      hours = (hours < 10) ? "0" + hours : hours;
      minutes = (minutes < 10) ? "0" + minutes : minutes;
      seconds = (seconds < 10) ? "0" + seconds : seconds;

      if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
          return "Tomorrow";
      }

      return hours + ":" + minutes + ":" + seconds;
    }

    return (
        <div className={classes.NextBattle}>
            <div className={classes.NextBattleTitle}>NEXT BATTLE</div>
            <div className={classes.NextBattleTimer}>{msToHMS(msTimeLeft)}</div>
        </div>
    )
}

export default NextBattleTimer;