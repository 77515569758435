import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const CurrentRouteProvider = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const getToolbarBtn = () => {
        switch (location.pathname) {
            case "/list": return "schedule";
            case "/new": return "new question";
            case "/edit": return "new question";
            case "/released": return "released";
            case "/login": return "";
            default: return "schedule";
        }
    }

    useEffect(() => {
        dispatch({
            type: "setActiveToolbarBtn",
            payload: {
                activeToolbarBtn: getToolbarBtn()
            }
        })
        if (location.pathname !== "/edit") {
            dispatch({
                type: "setIsEditing",
                payload: {
                    isEditing: false
                }
            })
        }
    }, [location])

    return null;
}

export default CurrentRouteProvider;