import Layout from "./components/Layout/Layout";
import ScreenSizeProvider from "./components/ScreenSizeProvider/ScreenSizeProvider";
import React from "react";
import GameDataService from "./components/GameDataService/GameDataService";
import CurrentRouteProvider from "./components/CurrentRouteProvider/CurrentRouteProvider";

function App() {
  return (
    <React.Fragment>
      <React.StrictMode>
        <ScreenSizeProvider/>
        <CurrentRouteProvider/>
        <GameDataService/>
        <Layout/>
      </React.StrictMode>
    </React.Fragment>
  );
}

export default App;
