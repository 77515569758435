import React, { useCallback, useEffect, useRef, useState } from "react";
import { Graphics, Text, useTick } from "@inlet/react-pixi";
import { TextStyle } from "pixi.js"
import _ from "lodash";

const GridSquare = (props) => {

    const colors = {
        green: 0x40ee40,
        blue: 0xccccff,
        darkGrey: 0xaaaaaa,
        grey: 0xfefefe
    }

    const { 
        imageDimensions,
        guessedTiles,
        setGuessedTiles,
        squareSize,
        partialGuess,
        wrongTiles,
        isGameOver,
        correctTiles,
    } = props;

    const [isSelected, setIsSelected] = useState(false);
    const [tileScale, setTileScale] = useState(1);
    const [finishedGrowing, setFinishedGrowing] = useState(false);
    const [zIndex, setZIndex] = useState(1);

    let i = 0;

    useTick(delta => {
        i += 0.023 * delta;

        if ((isSelected && tileScale < 1.2 && !finishedGrowing)) {
            setTileScale(i + tileScale);
            setZIndex(1 * (wrongTiles.length + 2));
        } else if (tileScale > 1.2) {
            setFinishedGrowing(true);
        } 

        if (finishedGrowing && tileScale > 1) {
            setTileScale(tileScale - i)
        } else if (tileScale < 1) {
            setTileScale(1)
        }
        
    })

    const useClickListener = () => {

        const onPress = useCallback(e => {
            
            if (!isGameOver) {
                if (guessedTiles.includes(props.tileNum)) {
                return
            } else if (!guessedTiles.includes(props.tileNum) && !wrongTiles.includes(props.tileNum)) {

                setIsSelected(true)

                if (!partialGuess[0]) {

                    if (!!guessedTiles[1]) {
                        setGuessedTiles([guessedTiles[1], props.tileNum])
                    } else if (!!guessedTiles[0]) {
                        setGuessedTiles([guessedTiles[0], props.tileNum])
                    } else {
                        setGuessedTiles([props.tileNum, null])
                    }

                } else if (!!partialGuess[0]) {

                    if (partialGuess[1] === 1) {
                        setGuessedTiles([partialGuess[0], props.tileNum])
                    } else if (partialGuess[1] === 2) {
                        setGuessedTiles([props.tileNum, partialGuess[0]])
                    }

                }
            }
        }
        
        }, [guessedTiles, partialGuess, wrongTiles, isGameOver, correctTiles]);
        return {
            pointerdown: onPress,
            tint: (correctTiles.includes(props.tileNum) && isGameOver) && (correctTiles.includes(guessedTiles[0]) && correctTiles.includes(guessedTiles[1])) ? colors.green : partialGuess[0] === props.tileNum ? colors.green : wrongTiles.includes(props.tileNum) ? colors.darkGrey : isSelected && guessedTiles.includes(props.tileNum) ? colors.blue : colors.grey,
            cursor: "pointer"
        } 
    }

    const drawSquare = useCallback(g => {
        g.clear();
        
        g.beginFill(0xeeeeee);
        g.lineStyle(imageDimensions.width < 500 ? 1 : imageDimensions.width < 1000 ? 2 : 3, 0x000000);

        g.drawRect(
            0, 
            0, 
            props.squareSize, 
            props.squareSize
        );

    }, [props.squareSize, imageDimensions.width])

    const bindClickListener = useClickListener();

    return (
            <React.Fragment>
                    <Graphics 
                        draw={drawSquare}
                        interactive={true}
                        scale={tileScale}
                        pivot={props.squareSize / 2}
                        zIndex={zIndex}
                        {...bindClickListener}
                        {...props}
                    >
                        <Text
                            text={props.tileNum}
                            style={
                                new TextStyle({
                                    align: "center",
                                    fontFamily: "'Oswald', sans-serif",
                                    fontSize: squareSize * 0.64,
                                })
                            }
                            anchor={0.5}
                            position={{x: squareSize / 2, y: squareSize / 2}}
                        />
                    </Graphics>
            </React.Fragment>
    )
}
  
export default GridSquare;
  