import classes from "./AgeModal.module.css";
import { setResponsiveClassName } from "../../../utility/utilityFunctions";
import { useSelector, useDispatch } from "react-redux";

const AgeModal = (props) => {
    const dispatch = useDispatch();
    const screenSize = useSelector(state => state.screenSize);

    const clickOver18Handler = () => {
        dispatch({
            type: "setShowAgeModal",
            payload: {
                showAgeModal: false
            }
        })
    }

    const clickLeaveHandler = () => {
            window.history.back();
        }

    return (
      props.showModal ? <div className={setResponsiveClassName(classes, screenSize, "AgeModalBackground")}>
        <div className={setResponsiveClassName(classes, screenSize, "AgeModal")}>
            <div className={classes.AgeModalTitle}>AGE VERIFICATION</div>
            <div className={classes.AgeModalText}>BattleNips is an adult website. It contains adult-oriented, age-restricted content. You must be at least 18 years old to enter.</div>
            <div onClick={clickOver18Handler} className={`${classes.AgeModalButton} ${classes.AgeModalButtonGreen}`}>I AM OVER 18</div>
            <div onClick={clickLeaveHandler} className={`${classes.AgeModalButton} ${classes.AgeModalButtonBlack}`}>LEAVE</div>
        </div>
      </div> : null
    );
  }
  
  export default AgeModal;
  