import React from "react";
import Login from "../../components/Login/Login";
import { useSelector } from "react-redux";

import classes from "./LoginPage.module.css";

const LoginPage = () => {
    const app = useSelector(state => state.firebaseApp)

    return (
        <div className={classes.LoginPage}>
            {app ? <Login/> : null}
        </div>
    );
}
  
export default LoginPage;
  