import classes from "./GameStatus.module.css";
import { setResponsiveClassName } from "../../../utility/utilityFunctions";
import { useSelector } from "react-redux";

import StatusNum from "./StatusNum/StatusNum";

const GameStatus = (props) => {
  const screenSize = useSelector(state => state.screenSize);

    return (
      <div className={setResponsiveClassName(classes, screenSize, "GameStatus")}>
        <div className={classes.RemAttempts}>REMAINING ATTEMPTS</div>
        <div className={classes.Tile1}>TILE 1</div>
        <div className={classes.Tile2}>TILE 2</div>
        <StatusNum number={props.guessesLeft} isGuessNumber={true}/>
        <StatusNum number={Math.round(props.guessedTiles[0])}/>
        <StatusNum number={Math.round(props.guessedTiles[1])}/>
      </div>
    );
  }
  
  export default GameStatus;
  