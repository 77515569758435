import React from "react";
import { useSelector } from "react-redux";

import classes from "./ToolbarBtns.module.css"
import ToolbarBtn from "./ToolbarBtn/ToolbarBtn";

const ToolbarBtns = (props) => {
    const screenSize = useSelector(state => state.screenSize);

    const toolbarButtons = [ 
        {name: "Schedule", linkTo: "/list"}, 
        {name: "Released", linkTo: "/released"},
        {name: "New Question", linkTo: "/new"},
        {name: "Log out", linkTo: "/login"}, 
    ]

    return (
            <div className={classes.ToolbarBtnsLarge}>
                {toolbarButtons.map(button => {
                    return <ToolbarBtn 
                        name={button.name} 
                        linkTo={button.linkTo} 
                        btnColor={props.btnColor} 
                        key={toolbarButtons.indexOf(button)}/>
                })}
            </div>
    )
}

export default ToolbarBtns;