import React from "react";
import NewQuestion from "../../components/NewQuestion/NewQuestion";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import Toolbar from "../../components/Toolbar/Toolbar"

import classes from "./NewQuestionPage.module.css";

const NewQuestionPage = () => {
    const loggedIn = useSelector(state => state.loggedIn);

    return (
        loggedIn ?
        <div className={classes.NewQuestionPage}>
            <Toolbar/>
            <NewQuestion/>
            
        </div> : <Navigate to="/login"/>
    );
}
  
export default NewQuestionPage;
  