import { useRef } from "react";
import { useSelector } from "react-redux";
import { Sprite } from "@inlet/react-pixi";

const PreviewBackgroundImage = (props) => {
    return (
        <Sprite
            {...props}
        >
            {props.children}
        </Sprite>
    );
  }
  
  export default PreviewBackgroundImage;
  

