import { IoMailOutline, IoLockClosedOutline } from "react-icons/io5";
import classes from "./Sidebar.module.css"
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const navigation = [
  { name: 'Privacy policy', icon: IoLockClosedOutline, href: '#', current: false },
  { name: 'Submit feedback', icon: IoMailOutline, href: 'mailto:support@battlenips.app?subject=BattlenipsFeedback', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Sidebar(props) {
  const dispatch = useDispatch();

  const showSidebar = useSelector(state => state.showSidebar);

  const modalClickHandler = () => {
    dispatch({
      type: "setShowSidebar",
      payload: {
        showSidebar: false
      }
    })
  }

  return (
    <>
    <div 
      className={`${classes.Modal} ${showSidebar ? classes.ShowModal : ""}`}
      onClick={modalClickHandler}/>

    <div className={`flex-1 flex flex-col min-h-0 ${classes.Sidebar} ${showSidebar ? classes.ShowSidebar : ""}`}>
      <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
        <div className={classes.SidebarTitle}>
          Battlenips
        </div>
        <nav className="mt-5 flex-1 px-2 space-y-1" aria-label="Sidebar">
          {navigation.map((item) => (
            item.name !== "Privacy policy" ?
            <a
              key={item.name}
              href={item.href}
              className={classNames(
                item.current ? 'bg-gray-900 text-white' : 'text-gray-100 hover:bg-gray-700 hover:text-white',
                'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
              )}
            >
              <item.icon
                className={classNames(
                  item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                  'mr-3 flex-shrink-0 h-6 w-6'
                )}
                aria-hidden="true"
              />
              <span className="flex-1">{item.name}</span>
            </a> : <Link to="/privacy">
                  <div
                  key={item.name}
              href={item.href}
              className={classNames(
                item.current ? 'bg-gray-900 text-white' : 'text-gray-100 hover:bg-gray-700 hover:text-white',
                'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
              )}
            >
              <item.icon
                className={classNames(
                  item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                  'mr-3 flex-shrink-0 h-6 w-6'
                )}
                aria-hidden="true"
              />
              <span className="flex-1">{item.name}</span>
                  </div>
            </Link>
          ))}
        </nav>
      </div>
      <div className={`flex-shrink-0 flex p-4 ${classes.CopyrightContainer}`}>
        <a href="#" className="flex-shrink-0 w-full group block">
          <div className={`flex items-center ${classes.Copyright}`}>
            © {new Date(Date.now()).getFullYear()} BattleNips 
          </div>
        </a>
      </div>
    </div>
    </>
  )
}