import { Sprite } from "@inlet/react-pixi";

const BackgroundImage = (props) => {
    return (
        <Sprite
            {...props}
            sortableChildren={true}
        >
            {props.children}
        </Sprite>
    );
  }
  
  export default BackgroundImage;
  

