import React, { useEffect } from "react";
import GamePage from "../../pages/GamePage/GamePage";
import { Routes, Route } from "react-router-dom";
import { useLocation } from "react-router";
import { useInterval } from "../../utility/utilityFunctions";

import classes from "./Layout.module.css";
import LoginPage from "../../pages/LoginPage/LoginPage";
import ListPage from "../../pages/ListPage/ListPage";
import ReleasedListPage from "../../pages/ReleasedListPage/ReleasedListPage";
import NewQuestionPage from "../../pages/NewQuestionPage/NewQuestionPage";
import PrivacyPage from "../../pages/PrivacyPage/PrivacyPage";

const Layout = () => {
  const location = useLocation();

  
    useEffect(() => {
      if (location.pathname !== "/") {
        const ad = document.querySelector("iframe")
        if (ad) {
          ad.parentElement.removeChild(ad)
        }
      }
    }, [location.pathname])

    useInterval(() => {
      if (location.pathname !== "/") {
        const ad = document.querySelector("iframe")
        if (ad) {
          ad.parentElement.removeChild(ad)
        }
      }
    }, 500)
  

    return (
      <div className={classes.Layout}>
        <Routes>
          <Route path="/new" element={<NewQuestionPage/>}>

          </Route>
          <Route path="/edit" element={<NewQuestionPage/>}>

          </Route>
          <Route path="/released" element={<ReleasedListPage/>}>

          </Route>
          <Route path="/list" element={<ListPage/>}>

          </Route>
          <Route path="/login" element={<LoginPage/>}>
            
          </Route>
          <Route path="/privacy" element={<PrivacyPage/>}>
            
          </Route>
          <Route path="/" element={<GamePage/>}>
            
          </Route>
        </Routes>
        
      </div>
    );
  }
  
  export default Layout;
  