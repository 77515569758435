import { useEffect, useState, useRef } from "react";
import classes from "./GameContainer.module.css";
import { setResponsiveClassName } from "../../utility/utilityFunctions";
import { useSelector, useDispatch } from "react-redux";

import AgeModal from "./AgeModal/AgeModal";
import StatisticsModal from "./StatisticsModal/StatisticsModal";
import HelpModal from "./HelpModal/HelpModal";
import Header from "./Header/Header";
import GameScreen from "./GameScreen/GameScreen";
import GameStatus from "./GameStatus/GameStatus";
import AdSpace from "./AdSpace/AdSpace";
import NextBattleTimeHandler from "./NextBattleTimeHandler/NextBattleTimeHandler";
import Sidebar from "./Sidebar/Sidebar";


const GameContainer = () => {
  const dispatch = useDispatch();

  const screenSize = useSelector(state => state.screenSize);
  const showAgeModal = useSelector(state => state.showAgeModal);
  const showHelpModal = useSelector(state => state.showHelpModal);
  const guessedTiles = useSelector(state => state.guessedTiles);
  const isGameOver = useSelector(state => state.isGameOver);
  const guessesLeft = useSelector(state => state.guessesLeft);
  const correctTiles = useSelector(state => state.questionData.correctTiles);
  const userData = useSelector(state => state.userData);
  const questionData = useSelector(state => state.questionData);
  const partialGuess = useSelector(state => state.partialGuess);
  const turnsGuessedCorrect = useSelector(state => state.turnsGuessedCorrect);
  const screenDimensions = useSelector(state => state.screenDimensions);

  const {
    maxStreak,
    currentStreak,
    guessNums,
    gamesPlayed,
    winRate
  } = userData;

  const [pixiGuessedTiles, setPixiGuessedTiles] = useState([null, null]);

  useEffect(() => {
    if (!pixiGuessedTiles.includes(guessedTiles[0]) || !pixiGuessedTiles.includes(guessedTiles[1])) {
      setPixiGuessedTiles(guessedTiles);
    }
    
  }, [guessedTiles])

  useEffect(() => {
    if (localStorage.getItem("visitedBefore") !== "true") {
      dispatch({
        type: "setShowAgeModal",
        payload: {
          showAgeModal: true
        }
      })
    }
  }, [])

  useEffect(() => {
    if (localStorage.getItem("visitedBefore") !== "true" && questionData.questionLoaded) {
      localStorage.setItem("visitedBefore", "true");
      setTimeout(() => {
        dispatch({
          type: "setShowHelpModal",
          payload: {
            showHelpModal: true
          }
        })
      }, 3000);
    }
  }, [questionData.questionLoaded])

  function preventMotion(event)
  {
      window.scrollTo(0, 0);
      event.preventDefault();
      event.stopPropagation();
  }

  const submitGuessHandler = () => {

    if (correctTiles.includes(guessedTiles[0]) && correctTiles.includes(guessedTiles[1])) {
      dispatch({
        type: "setGameWon",
        payload: {
          gameWon: true
        }
      })

      dispatch({
        type: "setIsGameOver",
        payload: {
          isGameOver: true
        }
      })

      if (partialGuess[0]) {
        dispatch({
          type: "setTurnsGuessedCorrect",
          payload: {
            turnsGuessedCorrect: [turnsGuessedCorrect[0], 7 - guessesLeft]
          }
        })
      } else if (!partialGuess[0]) {
        dispatch({
          type: "setTurnsGuessedCorrect",
          payload: {
            turnsGuessedCorrect: [7 - guessesLeft, 7 - guessesLeft]
          }
        })
      }

    } else if ( ( correctTiles.includes(guessedTiles[0]) || correctTiles.includes(guessedTiles[1]) ) ) {

      let newPartialGuess = [];
      let newWrongGuess = null;
      if (correctTiles.includes(guessedTiles[0])) {

        newPartialGuess = [guessedTiles[0], 1]
        newWrongGuess = [guessedTiles[1]]

        dispatch({
          type: "setGuessedTiles",
          payload: {
            guessedTiles: [guessedTiles[0], null]
          }
        })

        if (correctTiles.includes(guessedTiles[1])) {
          dispatch({
            type: "setTurnsGuessedCorrect",
            payload: {
              turnsGuessedCorrect: [turnsGuessedCorrect[0], 7 - guessesLeft]
            }
          })
        } else if (!turnsGuessedCorrect[0]) {
          dispatch({
            type: "setTurnsGuessedCorrect",
            payload: {
              turnsGuessedCorrect: [7 - guessesLeft, null]
            }
          })
        }

        

      } else {
        newPartialGuess = [guessedTiles[1], 2]
        newWrongGuess = [guessedTiles[0]]

        dispatch({
          type: "setGuessedTiles",
          payload: {
            guessedTiles: [null, guessedTiles[1]]
          }
        })
      }

      

      dispatch({
        type: "setPartialGuess",
        payload: {
          partialGuess: newPartialGuess
        }
      })

      dispatch({
        type: "setWrongTiles",
        payload: {
          wrongTiles: newWrongGuess
        }
      })

      dispatch({
        type: "setGuessesLeft",
        payload: {
          guessesLeft: guessesLeft - 1
        }
      })
    } else {
      dispatch({
        type: "setWrongTiles",
        payload: {
          wrongTiles: guessedTiles
        }
      })

      dispatch({
        type: "setGuessedTiles",
        payload: {
          guessedTiles: [null, null]
        }
      })

      setPixiGuessedTiles([null, null])

      dispatch({
        type: "setGuessesLeft",
        payload: {
          guessesLeft: guessesLeft - 1
        }
      })
    }
  }

  useEffect(() => {
    if (guessesLeft <= 0) {
      dispatch({
        type: "setIsGameOver",
        payload: {
          isGameOver: true
        }
      })
    }
  }, [guessesLeft])

  useEffect(() => {
    if (isGameOver && !userData.questionsCompleted.includes(questionData.questionID)) {
      setTimeout(() => {
        dispatch({
          type: "setShowStatisticsModal",
          payload: {
            showStatisticsModal: true
          }
        });
      }, 4000);
    }
  }, [isGameOver])

  useEffect(() => {
    if ((guessedTiles[0] && guessedTiles[1]) && !isGameOver) {
          submitGuessHandler();
    }
  }, [guessedTiles])

  useEffect(() => {
    const gameContainerDOM = document.querySelector("#gamecontainer")
    gameContainerDOM.addEventListener("touchmove", preventMotion, { passive: false });
  }, [])  

  return (
    <div 
      className={setResponsiveClassName(classes, screenSize, "GameContainer")}
      style={screenSize === "mobile" ? {height: screenDimensions.height} : {}}
      id="gamecontainer"> 
      <Sidebar/>
      <AgeModal showModal={showAgeModal}/>
      <StatisticsModal
        gamesPlayed={gamesPlayed}
        winPercentage={winRate}
        currentStreak={currentStreak}
        maxStreak={maxStreak}
        guessNums={guessNums}
        timeLeft={12 * 60 * 60 * 1000}
      />
      <HelpModal showModal={showHelpModal}/>
      <Header/>
      <GameScreen
        guessedTiles={pixiGuessedTiles}
        setGuessedTiles={setPixiGuessedTiles}/>
      <GameStatus
        guessesLeft={guessesLeft}
        guessedTiles={guessedTiles}
      />
      <AdSpace/>
      <NextBattleTimeHandler/>
    </div>
  );
}

export default GameContainer;
  