import { useRef, useEffect } from "react";

export const setResponsiveClassName = (classes, screenSize, baseClassName) => {
    let responsiveClassString = `
        ${eval(`classes.${baseClassName}`)} ${screenSize === "desktop" ? 
        eval(`classes.${baseClassName}Desktop`) :
        screenSize === "mobile" ? eval(`classes.${baseClassName}Mobile`) : null}`;
    return responsiveClassString;
}

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
      savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
      function tick() {
      savedCallback.current();
      }
      if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
      }
  }, [delay]);
}