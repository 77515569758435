import classes from "./Header.module.css";
import { setResponsiveClassName } from "../../../utility/utilityFunctions";
import { useSelector } from "react-redux";
import HeaderItems from "./HeaderItems/HeaderItems";

const Header = () => {
  const screenSize = useSelector(state => state.screenSize);

    return (
      <div className={setResponsiveClassName(classes, screenSize, "Header")}>
        <HeaderItems/>
      </div>
    );
  }
  
  export default Header;
  