import { ChevronRightIcon } from '@heroicons/react/solid'
import { Navigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

import classes from "./QuestionItem.module.css";

const QuestionItem = (props) => {
    const dispatch = useDispatch();

    const [redirect, setRedirect] = useState(null);

    const itemClickHandler = () => {
        setRedirect(<Navigate to="/new"/>);
        dispatch({
            type: "setEditQuestion",
            payload: { 
                editQuestion: {
                    isEditing: true,
                    questionData: props.question
                }
            }
        })
        dispatch({
            type: "setNewImgURL",
            payload: { 
                imgURL: props.question.imgURL
            }
        })
        setRedirect(<Navigate to="/edit"/>)
    }
    
    return (
        <li onClick={itemClickHandler}>
            <div href="/" className="block hover:bg-gray-50">
                <div className={`flex items-center px-4 py-4 sm:px-6 ${classes.QuestionItem}`}>
                    <div className="min-w-0 flex-1 flex items-center">
                        <div className="flex-shrink-0">
                            <img className="h-12 w-12 rounded-full" src={props.question.imgURL} alt="" />
                        </div>
                        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                            <div className={classes.QuestionID}>
                                ID: {props.question.questionID}
                            </div>
                            <div className="hidden md:block">
                            <div>
                                <p className={classes.ReleaseDate}>
                                    Release date: {new Date(props.question.releaseTimeUNIX).toLocaleDateString("en-US")}
                                </p>
                                <p className="mt-2 flex items-center">
                                    Release time: {new Date(props.question.releaseTimeUNIX).toLocaleTimeString("en-US")}
                                </p>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div>
                    <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                </div>
            </div>
            {redirect}
        </li>
    );
}
  
export default QuestionItem;
  