import classes from "./HeaderItems.module.css";
import { setResponsiveClassName } from "../../../../utility/utilityFunctions";
import { useSelector } from "react-redux";
import HeaderItem from "./HeaderItem/HeaderItem";

const HeaderItems = () => {
  const screenSize = useSelector(state => state.screenSize);

  const dummyItemData = [
    {
      itemType: "sidedrawer"
    },
    {
      itemType: "help"
    },
    {
      itemType: "title"
    },
    {
      itemType: "statistics"
    },
    {
      itemType: "share"
    }
  ]

    return (
      <div className={setResponsiveClassName(classes, screenSize, "HeaderItems")}>
        {dummyItemData.map((itemData, i) => {
            return <HeaderItem key={i} itemType={itemData.itemType}/>
          })
        }
      </div>
    );
  }
  
  export default HeaderItems;
  