import { createStore } from "redux";

const setMsTimeLeft = (state, msTimeLeft) => {
    let newQuestionData = JSON.parse(JSON.stringify(state.questionData));
    newQuestionData.msTimeLeft = msTimeLeft;
    return newQuestionData;
}

const setGridPosition = (state, gridPosition) => {
    let newQuestionData = JSON.parse(JSON.stringify(state.newQuestionData));
    newQuestionData.gridPosition = gridPosition;
    return newQuestionData;
}

const setGridDimensions = (state, gridDimensions) => {
    let newQuestionData = JSON.parse(JSON.stringify(state.newQuestionData));
    newQuestionData.gridDimensions = gridDimensions;
    return newQuestionData;
}

const setSquareSize = (state, squareSize) => {
    let newQuestionData = JSON.parse(JSON.stringify(state.newQuestionData));
    if (squareSize > 0) {newQuestionData.squareSize = squareSize};
    return newQuestionData;
}

const setCorrectTiles = (state, correctTiles) => {
    let newQuestionData = JSON.parse(JSON.stringify(state.newQuestionData));
    newQuestionData.correctTiles = correctTiles;
    return newQuestionData;
}

const setReleaseTimeUNIX = (state, releaseTimeUNIX) => {
    let newQuestionData = JSON.parse(JSON.stringify(state.newQuestionData));
    newQuestionData.releaseTimeUNIX = releaseTimeUNIX;
    return newQuestionData;
}

const setIsEditing = (state, isEditing) => {
    let newEditQuestion = JSON.parse(JSON.stringify(state.editQuestion));
    newEditQuestion.isEditing = isEditing
    return newEditQuestion;
}

const setQuestionID = (state, questionID) => {
    let newQuestionData = JSON.parse(JSON.stringify(state.newQuestionData));
    newQuestionData.questionID = questionID;
    return newQuestionData;
}

const setImgURL = (state, imgURL) => {
    let newQuestionData = JSON.parse(JSON.stringify(state.newQuestionData));
    newQuestionData.imgURL = imgURL;
    return newQuestionData;
}

const mainReducer = (state = { 
        screenSize: "mobile",
        screenDimensions: { width: 100, height: 100 },
        barHeight: 50,

        loggedIn: false,

        questionData: {
            gridPosition: { x: 0, y: 0 },
            gridDimensions: {
                a: 10,
                b: 10
            },
            squareSize: 25,
            correctTiles: [1, 2],
            msTimeLeft: 12 * 60 * 60 * 1000,
            imgURL: "https://i.imgur.com/fDEO03m.jpg",
            questionID: "1",
            questionLoaded: false,
        },

        newQuestionData: {
            gridPosition: { x: null, y: null },
            gridDimensions: { a: null, b: null},
            squareSize: null,
            correctTiles: [null, null],
            releaseTimeUNIX: null,
            imgURL: "https://i.imgur.com/eId1Rn5.jpg",
            questionID: ""
        },
        sendQuestion: false,
        editQuestion: {
            isEditing: false,
            questionData: {}
        },
        updatingQuestion: false,
        deletingQuestion: false,
        imageUpdating: false,

        unreleasedQuestions: [],
        needUnreleasedQuestions: false,
        releasedQuestions: [],
        needReleasedQuestions: false,

        showAgeModal: false,
        showStatisticsModal: false,
        showHelpModal: false,
        showSidebar: false,

        timerOn: true,
        
        isGameOver: false,
        gameWon: false,
        gameLost: false,
        guessesLeft: 6,
        guessedTiles: [null, null],
        wrongTiles: [],
        turnsGuessedCorrect: [null, null],
        
        userData: {
            currentStreak: 0,
            maxStreak: 0,
            gamesPlayed: 0,
            gamesWon: 0,
            gamesLost: 0,
            guessNums: [0, 0, 0, 0, 0, 0],
            winRate: 0,
            questionsCompleted: [],
            userLoaded: false
        },
        partialGuess: [null, null],

        firebaseApp: null,

    }, action ) => {
    switch (action.type) {
        case "setScreenSize": return { ...state, screenSize: action.payload.screenSize } || state;
        case "setScreenDimensions": return { ...state, screenDimensions: action.payload.screenDimensions } || state;
        case "setBarHeight": return { ...state, barHeight: action.payload.barHeight } || state;

        case "setGuessedTiles": return { ...state, guessedTiles: action.payload.guessedTiles } || state;
        case "setWrongTiles": return { ...state, wrongTiles: [...state.wrongTiles, ...action.payload.wrongTiles] } || state;

        case "setShowAgeModal": return { ...state, showAgeModal: action.payload.showAgeModal } || state;
        case "setShowStatisticsModal": return { ...state, showStatisticsModal: action.payload.showStatisticsModal } || state;
        case "setShowHelpModal": return { ...state, showHelpModal: action.payload.showHelpModal } || state;
        case "setShowSidebar": return { ...state, showSidebar: action.payload.showSidebar } || state;

        case "setIsGameOver": return { ...state, isGameOver: action.payload.isGameOver } || state;
        case "setGuessesLeft": return { ...state, guessesLeft: action.payload.guessesLeft } || state;
        case "setTurnsGuessedCorrect": return { ...state, turnsGuessedCorrect: action.payload.turnsGuessedCorrect } || state;
        case "setPartialGuess": return { ...state, partialGuess: action.payload.partialGuess } || state;
        case "setGameWon": return { ...state, gameWon: action.payload.gameWon } || state;

        case "setMsTimeLeft": return { ...state, questionData: setMsTimeLeft(state, action.payload.msTimeLeft) } || state;

        case "setUserData": return { ...state, userData: action.payload.userData } || state;
        case "setQuestionData": return { ...state, questionData: action.payload.questionData } || state;

        case "setIsLoggedIn": return { ...state, loggedIn: action.payload.loggedIn } || state;

        case "setNewGridPosition": return { ...state, newQuestionData: setGridPosition(state, action.payload.gridPosition) } || state;
        case "setNewGridDimensions": return { ...state, newQuestionData: setGridDimensions(state, action.payload.gridDimensions) } || state;
        case "setNewSquareSize": return { ...state, newQuestionData: setSquareSize(state, action.payload.squareSize) } || state;
        case "setNewCorrectTiles": return { ...state, newQuestionData: setCorrectTiles(state, action.payload.correctTiles) } || state;
        case "setNewReleaseTimeUNIX": return { ...state, newQuestionData: setReleaseTimeUNIX(state, action.payload.releaseTimeUNIX) } || state;
        case "setNewQuestionID": return { ...state, newQuestionData: setQuestionID(state, action.payload.questionID) } || state;
        case "setNewImgURL": return { ...state, newQuestionData: setImgURL(state, action.payload.imgURL) } || state;
        case "setSendQuestion": return { ...state, sendQuestion: action.payload.sendQuestion } || state;
        case "setEditQuestion": return { ...state, editQuestion: action.payload.editQuestion } || state;
        case "setUpdatingQuestion": return { ...state, updatingQuestion: action.payload.updatingQuestion } || state;
        case "setNewQuestionData": return { ...state, newQuestionData: action.payload.newQuestionData } || state;
        case "setIsEditing": return { ...state, editQuestion: setIsEditing(state, action.payload.isEditing) } || state;
        case "setDeletingQuestion": return { ...state, deletingQuestion: action.payload.deletingQuestion } || state;

        case "setImageUpdating": return { ...state, imageUpdating: action.payload.imageUpdating } || state;

        case "setUnreleasedQuestions": return { ...state, unreleasedQuestions: action.payload.unreleasedQuestions } || state;
        case "setNeedUnreleasedQuestions": return { ...state, needUnreleasedQuestions: action.payload.needUnreleasedQuestions } || state;
        case "setReleasedQuestions": return { ...state, releasedQuestions: action.payload.releasedQuestions } || state;
        case "setNeedReleasedQuestions": return { ...state, needReleasedQuestions: action.payload.needReleasedQuestions } || state;

        case "setFirebaseApp": return { ...state, firebaseApp: action.payload.app } || state;

        case "setActiveToolbarBtn": return { ...state, activeToolbarBtn: action.payload.activeToolbarBtn } || state;

        default: return state;
    }
};

const store = createStore(mainReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;