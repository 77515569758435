import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import QuestionItem from "../QuestionList/QuestionItem/QuestionItem";

import classes from "./ReleasedQuestionList.module.css";

export default function ReleasedQuestionList() {
  const dispatch = useDispatch();

  const releasedQuestions = useSelector(state => state.releasedQuestions);

  useEffect(() => {
    dispatch({
      type: "setNeedReleasedQuestions",
      payload: {
        needReleasedQuestions: true
      }
    })
  }, [])

    return (
        <div className={classes.QuestionListContainer}>
            <div className={`bg-white shadow overflow-hidden sm:rounded-md ${classes.QuestionList}`}>
                <ul role="list" className="divide-y divide-gray-200">
                    {releasedQuestions.map((question, i) => (
                      <QuestionItem question={question} key={i}/>
                    ))}
                </ul>
            </div>
                <Link className={classes.NewQuestion} to="/new">+</Link>
        </div>
  )
}