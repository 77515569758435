import classes from "./StatisticsModal.module.css";
import { setResponsiveClassName } from "../../../utility/utilityFunctions";
import { useSelector, useDispatch } from "react-redux";
import { IoCloseOutline } from "react-icons/io5";
import { IoShareSocialOutline } from "react-icons/io5";
import { useInterval } from "../../../utility/utilityFunctions";
import React, { useCallback, useState, useEffect, useRef } from "react";
import NextBattleTimer from "./NextBattleTimer/NextBattleTimer";

const StatisticsModal = (props) => {
    const dispatch = useDispatch();

    const screenSize = useSelector(state => state.screenSize);
    const showStatisticsModal = useSelector(state => state.showStatisticsModal);
    const turnsGuessedCorrect = useSelector(state => state.turnsGuessedCorrect);
    const isGameOver = useSelector(state => state.isGameOver);

    const [animationClasses, setAnimationClasses] = useState(classes.InitialHideModal);

    const renderCounter = useRef(0);

    const colors = {
        guessBarGray: "#383838",
        guessBarGreen: "#15ad50",
    }
   
    const getGuessBarWidth = useCallback ((index, extraPadding) => {
        const maxGuessNum = Math.max(...props.guessNums);
        const guessSum = props.guessNums.reduce(
            (prev, current) => prev + current, 0
        )
        let widthPercent = Math.round((props.guessNums[index] / guessSum) * 100);
        let widthMultiplier = guessSum / maxGuessNum;
        widthPercent = widthPercent * widthMultiplier;
        if (widthPercent <= 8 || maxGuessNum === 0) {
            widthPercent = 8;
        }
        const widthPercentString = `${extraPadding ? widthPercent - extraPadding : widthPercent}%`;

        return widthPercentString;
    }, [props.guessNums])

    const getGuessSquares = () => {
        let squareString = "";
        for (let i = 1; i < 7; i++) {
          if (i === turnsGuessedCorrect[0] && i === turnsGuessedCorrect[1]) {
            squareString = squareString + "(  o  Y  o  )\n"
            return squareString;
          } else if (i === turnsGuessedCorrect[0]) {
            squareString = squareString + "⬛🟩⬛⬛⬛\n";
          } else if (i === turnsGuessedCorrect[1]) {
            squareString = squareString + "(  o  Y  o  )\n"
            return squareString;
          } else {
            squareString = squareString + "⬛⬛⬛⬛⬛\n";
          }
        }
        return squareString;
      }
    
    const shareClickedHandler = () => {
    const date = new Date(Date.now())
    const formattedDate = `${date.getMonth() + 1}/${date.getDate()}`



    const shareData = {
        title: "BattleNips",
        text: `BattleNips ${formattedDate} - I challenge you to a game of BattleNips! Can you beat my score? \n${isGameOver ? getGuessSquares() : ""}`,
        url: "https://battlenips.app/",
    }

    try {
        navigator.share(shareData)
    } catch (e) {
        console.error("Error sharing question", e)
    }
    }

    const adSpaceRef = useRef();

    useEffect(() => {
    if (adSpaceRef.current) {
        let ad;
        if (document.querySelectorAll("iframe")[1]) {
            ad = document.querySelectorAll("iframe")[1]
        }
        if (ad) {
        adSpaceRef.current.appendChild(ad)
        }
    }
    }, [adSpaceRef.current])

    useInterval(() => {
    const ad = document.querySelectorAll("iframe")[1]
    if (ad) {
        if (!adSpaceRef.current.children.length) {
        adSpaceRef.current.appendChild(ad)
        }
    }
    }, 500)

    useEffect(() => {
        if (renderCounter.current > 0) {
            if (showStatisticsModal === false) {
                setAnimationClasses(`${classes.HideModal}`)
            } else if (showStatisticsModal === true) {
                setAnimationClasses(`${classes.ShowModal}`)
            }
        } else renderCounter.current++
    }, [showStatisticsModal])

    return <div className={`${setResponsiveClassName(classes, screenSize, "GameOverModal")} ${animationClasses}`}>
    <div className={classes.ExitX} onClick={() => {
        dispatch({
            type: "setShowStatisticsModal",
            payload: {
                showStatisticsModal: false
            }
        })
    }}>
        <IoCloseOutline
            color={"white"}
            size={"32px"}
        />
    </div>

    <div className={classes.Statistics}>
        <div className={classes.StatisticsTitle}>STATISTICS</div>
        <div className={classes.StatDisplays}>
            <div className={classes.StatDisplay}>
                <div className={classes.StatNumber}>{props.gamesPlayed}</div>
                <div className={classes.StatName}>Played</div>
            </div>
            <div className={classes.StatDisplay}>
                <div className={classes.StatNumber}>{props.winPercentage}</div>
                <div className={classes.StatName}>Win %</div>
            </div>
            <div className={classes.StatDisplay}>
                <div className={classes.StatNumber}>{props.currentStreak}</div>
                <div className={classes.StatName}>Current Streak</div>
            </div>
            <div className={classes.StatDisplay}>
                <div className={classes.StatNumber}>{props.maxStreak}</div>
                <div className={classes.StatName}>Max Streak</div>
            </div>
        </div>
        
    </div>
    <div className={classes.GuessGraphContainer}>
        <div className={classes.GuessGraphTitle}>GUESS DISTRIBUTION</div>

        <div className={classes.GuessGraph}>

            {props.guessNums.map((guessNum, i) => (
                <div className={classes.Guess} key={i}>
                    <div className={classes.GuessNumber}>{i + 1}</div>
                    <div 
                        className={classes.GuessBar}
                        style={props.guessNums[i] !== 0 ? {
                            backgroundColor: props.guessNums[i] !== 0 ? colors.guessBarGreen : colors.guessBarGray,
                            width: getGuessBarWidth(i),
                            paddingLeft: props.guessNums[i] < 10 ? getGuessBarWidth(i, 5) : getGuessBarWidth(i, 8),
                            textAlign: "center",
                            paddingRight: props.guessNums[i] !== 0 ? "0.4rem" : "0"
                        } : {
                            backgroundColor: props.guessNums[i] !== 0 ? colors.guessBarGreen : colors.guessBarGray,
                            width: getGuessBarWidth(i),
                            justifyContent: "center",
                            paddingRight: props.guessNums[i] !== 0 ? "0.4rem" : "0"
                        }}
                    >{guessNum}</div>
            </div>
            ))}

        </div>
    </div>
        <div className={classes.BtnTimerContainer}>
            <NextBattleTimer/>
            <button className={classes.ShareBtn} onClick={shareClickedHandler}>SHARE <IoShareSocialOutline color="white"/></button>
        </div>
        <div className={classes.StatsAdspace} ref={adSpaceRef}>

        </div>
    </div>
}
  
export default StatisticsModal;
  