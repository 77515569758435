import React, { useEffect, useState } from "react";
import SidebarInput from "./SidebarInput/SidebarInput";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router";
import { hex } from "mathjs";
import moment from "moment"

import classes from "./NewQuestionSidebar.module.css";

const NewQuestionSidebar = (props) => {
    const dispatch = useDispatch();

    const newQuestionData = useSelector(state => state.newQuestionData);
    const editQuestion = useSelector(state => state.editQuestion);
    
    const [redirect, setRedirect] = useState(null);
    const [releaseTime, setReleaseTime] = useState("04:00");
    const [releaseDate, setReleaseDate] = useState("");

    const generateUniqueID = () => {
        let userID;
        userID = hex(Math.round(Math.random() * 1000 * 1000 * 1000 * 1000 * 1000))
        return userID;
    }

    const isValidData = () => {
        let isValid = true;
            if (!newQuestionData.gridPosition.x) {
                isValid = false;
            } if (!newQuestionData.gridPosition.y) {
                isValid = false;
            } if (!newQuestionData.gridDimensions.a) {
                isValid = false;
            } if (!newQuestionData.gridDimensions.b) {
                isValid = false;
            } if (!newQuestionData.squareSize) {
                isValid = false;
            } if (!newQuestionData.correctTiles[0]) {
                isValid = false;
            } if (!newQuestionData.correctTiles[1]) {
                isValid = false;
            } if (!releaseDateUNIX) {
                isValid = false;
            } if (!releaseTimeUNIX) {
                isValid = false;
            }
        return isValid;
    }

    const timeToMs = (timeString) => {
        const timeArray = timeString.split(":");
        const hours = timeArray[0];
        const minutes = timeArray[1];
        const ms = hours * 60 * 60 * 1000 + minutes * 60 * 1000 + 5 * 3600 * 1000; // adding 5 hours to make it EST 
        return ms;
    }

    const UNIXtoTime = (date) => {
        const hours = "0" + date.getUTCHours();
        const minutes = "0" + date.getUTCMinutes();
        const formattedTime = hours.substr(-2) + ':' + minutes.substr(-2);
        return formattedTime;
    }

    const UNIXtoDate = (date) => {
        const month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
        const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        const year = date.getFullYear();
        const formattedTime = year + "-" + month + "-" + day;
        return formattedTime;
    }

    const [releaseDateUNIX, setReleaseDateUNIX] = useState(UNIXtoDate(new Date(Date.now())));
    const [releaseTimeUNIX, setReleaseTimeUNIX] = useState(timeToMs("04:00"));

    useEffect(() => {
        setReleaseDateUNIX( Math.floor( new Date( releaseDate ).getTime() ) )
    }, [releaseDate])

    useEffect(() => {
        setReleaseTimeUNIX(timeToMs(releaseTime))
    }, [releaseTime])

    useEffect(() => {
        const date = new Date(editQuestion.questionData.releaseTimeUNIX - 5 * 3600 * 1000)
        console.log(editQuestion.questionData.releaseTimeUNIX)
        if (editQuestion.isEditing) {
            setReleaseTime(UNIXtoTime(date));
            setReleaseDate(UNIXtoDate(date));
        }
    }, [editQuestion.isEditing])

    const dispatchReleaseTimeUNIX = (UNIXTimeSum) => {
        dispatch({
            type: "setNewReleaseTimeUNIX",
            payload: {
                releaseTimeUNIX: UNIXTimeSum
            }
        })
    }

    const dispatchSendQuestion = () => {
        dispatch({
            type: "setSendQuestion",
            payload: {
                sendQuestion: true
            }
        })
    }

    const dispatchUpdatingQuestion = () => {
        dispatch({
            type: "setUpdatingQuestion",
            payload: {
                updatingQuestion: true
            }
        })
    }

    const dispatchStopEditing = () => {
        dispatch({
            type: "setIsEditing",
            payload: {
                isEditing: false
            }
        })
    }

    const dispatchDeletingQuestion = () => {
        dispatch({
            type: "setDeletingQuestion",
            payload: {
                deletingQuestion: true
            }
        })
    }

    useEffect(() => {
        dispatch({
            type: "setNewQuestionID",
            payload: {
                questionID: generateUniqueID()
            }
        })
    }, [])

    useEffect(() => {
        dispatchReleaseTimeUNIX(releaseDateUNIX + releaseTimeUNIX);
    }, [releaseDateUNIX, releaseTimeUNIX])


    return (
        <div className={classes.NewQuestionSidebar}>
            <SidebarInput 
                name="Grid Position (px)" 
                type="gridPosition"
                doubleInput={true} 
                fieldNames={["x" , "y"]}/>
            <SidebarInput 
                name="Grid Dimensions" 
                type="gridDimensions"
                doubleInput={true} 
                fieldNames={["a" , "b"]}/>
            <SidebarInput 
                name="Answer Tiles"
                type="correctTiles" 
                doubleInput={true} 
                fieldNames={["1" , "2"]}/>
            <SidebarInput 
                name="Tile Size (px)"
                type="squareSize"/>
            <div className={classes.SidebarInputContainer}>
                <div className={classes.SidebarInputName}>
                    Release Date
                </div>
                <input 
                    className={classes.SidebarInputFieldSingle} 
                    type="date" 
                    value={releaseDate}
                    onInput={(e) => { setReleaseDate(e.target.value) }}/>
            </div>
            <div className={classes.SidebarInputContainer}>
                <div className={classes.SidebarInputName}>
                    Release Time
                </div>
                <input 
                    className={classes.SidebarInputFieldSingle} 
                    type="time" 
                    value={releaseTime}
                    onInput={(e) => { setReleaseTime(e.target.value) }}/>
            </div>
            <div className={classes.SidebarInputContainer}>
                <div className={classes.SidebarInputName}>
                    Upload Image
                </div>
                <input
                    className={classes.FileInput}
                    type="file"
                    onInput={(e) => { props.setImageFile(e.target.files[0]) }}
                />
            </div>
            {!editQuestion.isEditing ? <button 
                className={classes.SaveQuestionButton}
                onClick={() => {
                    if (isValidData()) {
                        dispatchSendQuestion()
                        setRedirect(<Navigate to="/list"/>)
                    } else {
                        alert("Invalid question data! \nMake sure you have entered all the necessary data into the inputs on the left")
                    }
                }}>
                    Save Question
            </button> : 
            <>
                <button
                    className={classes.SaveQuestionButton}
                    onClick={() => {
                        if (isValidData()) {
                            dispatchUpdatingQuestion()
                            dispatchStopEditing()
                            setRedirect(<Navigate to="/list"/>)
                        } else {
                            alert("Invalid question data!")
                        }
                    }}>
                        Save Changes
                </button>
                <button
                    className={classes.DeleteQuestionButton}
                    onClick={() => {
                            dispatchDeletingQuestion()
                            dispatchStopEditing()
                            setRedirect(<Navigate to="/list"/>)
                    }}>
                        Delete Question
                </button>
            </>}
            {redirect}
        </div>
    );
}
  
export default NewQuestionSidebar;
  