import { useSelector, useDispatch } from "react-redux";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, orderBy, doc, getDocs, query, where, limit, runTransaction, setDoc, deleteDoc } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { useEffect, useState } from "react";
import { hex } from "mathjs";
import Cookies from "js-cookie";

const GameDataService = () => {
    const dispatch = useDispatch();
    const userData = useSelector(state => state.userData);
    const isGameOver = useSelector(state => state.isGameOver);
    const gameWon = useSelector(state => state.gameWon);
    const app = useSelector(state => state.firebaseApp);
    const questionData = useSelector(state => state.questionData);
    const newQuestionData = useSelector(state => state.newQuestionData);
    const turnsGuessedCorrect = useSelector(state => state.turnsGuessedCorrect);
    const sendQuestion = useSelector(state => state.sendQuestion);
    const needUnreleasedQuestions = useSelector(state => state.needUnreleasedQuestions);
    const needReleasedQuestions = useSelector(state => state.needReleasedQuestions);
    const updatingQuestion = useSelector(state => state.updatingQuestion);
    const editQuestion = useSelector(state => state.editQuestion);
    const deletingQuestion = useSelector(state => state.deletingQuestion);

    const [db, setDb] = useState();
    const [storage, setStorage] = useState();

    const generateUniqueID = () => {
        let userID;
        userID = hex(Math.round(Math.random() * 1000 * 1000 * 1000 * 1000 * 1000))
        console.log(userID)
        return userID;
    }

    const firebaseConfig = {
        projectId: "battlenips-fcb55",
        databaseURL: "https://battlenips-fcb55.firebaseio.com",
        apiKey: "AIzaSyDtLQ0vX7Rmti5yfcADZwg0AB8XQe3FKCQ",
        storageBucket: "gs://battlenips-fcb55.appspot.com",
    }

    useEffect(() => {
        dispatch({
            type: "setFirebaseApp",
            payload: {
                app: initializeApp(firebaseConfig)
            }
        })
    }, [])
    
    const connectDB = async () => {
        try {
            if (app) {
                setDb(getFirestore(app));
            }
        } catch (e) {
            console.error("Error connecting with db", e);
        }
    }

    const connectStorage = async () => {
        try {
            if (app) {
                setStorage(getStorage(app));
            }
        } catch (e) {
            console.error("Error connecting with google storage", e);
        }
    }

    const getQuestion = async (userCompletedQuestions) => {
        try {
            const questionRef = collection(db, "Questions");
            const q = query(questionRef, where("releaseTimeUNIX", "<", Date.now()));
            const questionSnapshot = await getDocs(q);
            let questionData = [];
            questionSnapshot.forEach((doc) => {
                let data = doc.data();
                questionData.push(data)
            });
            if (questionData.length) {
                let correctQuestion;
                correctQuestion = questionData[questionData.length - 1];
                dispatch({
                    type: "setQuestionData",
                    payload: {
                        questionData: {...correctQuestion, questionLoaded: true }
                    }
                })
            }
            

        } catch (e) {
            console.error("Error getting question", e)
        };
    }

    const getUnreleasedQuestions = async () => {
        try {
            const questionRef = collection(db, "Questions");
            const q = query(questionRef, where("releaseTimeUNIX", ">", Date.now()));
            const questionSnapshot = await getDocs(q);
            let questionData = [];
            questionSnapshot.forEach((doc) => {
                let data = doc.data();
                questionData.push(data)
            });
            console.log(questionData)
            dispatch({
                type: "setUnreleasedQuestions",
                payload: {
                    unreleasedQuestions: questionData
                }
            })
            dispatch({
                type: "setNeedUnreleasedQuestions",
                payload: {
                    needUnreleasedQuestions: false
                }
            })
        } catch (e) {
            console.error("Error getting unreleased questions", e)
        }
    }

    const getReleasedQuestions = async () => {
        try {
            const questionRef = collection(db, "Questions");
            const q = query(questionRef, where("releaseTimeUNIX", "<", Date.now()), orderBy("releaseTimeUNIX", "desc"));
            const questionSnapshot = await getDocs(q);
            let questionData = [];
            questionSnapshot.forEach((doc) => {
                let data = doc.data();
                questionData.push(data)
            });
            console.log(questionData)
            dispatch({
                type: "setReleasedQuestions",
                payload: {
                    releasedQuestions: questionData
                }
            })
            dispatch({
                type: "setNeedReleasedQuestions",
                payload: {
                    needReleasedQuestions: false
                }
            })
        } catch (e) {
            console.error("Error getting unreleased questions", e)
        }
    }

    const getNextQuestionTime = async () => {
        try {
            const questionRef = collection(db, "Questions");
            const q = query(questionRef, where("releaseTimeUNIX", ">", Date.now()), limit(1));
            const questionSnapshot = await getDocs(q);
            let questionData = [];
            questionSnapshot.forEach((doc) => {
                let data = doc.data();
                questionData.push(data)
            });
            console.log(questionData)
            if (questionData[0]) {
                const msTimeLeft = questionData[0].releaseTimeUNIX - Date.now();
                dispatch({
                    type: "setMsTimeLeft",
                    payload: {
                        msTimeLeft: msTimeLeft
                    }
                })
            }
        } catch (e) {
            console.error("Error getting next question time", e);
        }
    }

    const getQuestionByID = async (questionID) => {
        try {
            const questionRef = collection(db, "Questions");
            const q = query(questionRef, where("questionID", "==", questionID), limit(1));
            const questionSnapshot = await getDocs(q);
            questionSnapshot.forEach((doc) => {
                let data = doc.data();
                dispatch({
                    type: "setNewQuestionData",
                    payload: {
                        newQuestionData: data
                    }
                })
            })
        } catch (e) {
            console.error("Error getting question by ID", e)
        }
    } 

    const newUser = async (userID = "userID not set") => {
        try {
            return await setDoc(doc(db, "Users", userID), {
                currentStreak: 0,
                gamesPlayed: 0,
                gamesWon: 0,
                gamesLost: 0,
                guessNums: [0, 0, 0, 0, 0, 0],
                maxStreak: 0,
                questionsCompleted: [],
                userID: userID,
                winRate: 0
            })
        } catch (e) {
            console.error("Error inserting user", e);
        }
    }

    const getUser = async (userID) => {
        try {
            const userRef = collection(db, "Users");
            const q = query(userRef, where("userID", "==", userID), limit(1))
            const userSnapshot = await getDocs(q);
            userSnapshot.forEach((doc) => {
                let data = doc.data();
                dispatch({
                    type: "setUserData",
                    payload: {
                        userData: {...data, userLoaded: true}
                    }
                })
            })
        } catch (e) {
            console.error("Error getting user", e)
        }
    }

    const updateUser = async (userID, newUserData) => {
        try {
            const userDocRef = doc(db, "Users", userID);
            return await runTransaction(db, async (transaction) => {
                const userDoc = await transaction.get(userDocRef);
                if (!userDoc.exists()) {
                    throw "User does not exist!";
                }

                transaction.update(userDocRef, newUserData);

            })
        } catch (e) {
            console.error("Error updating user stats", e)
        }
    }

    const newQuestion = async () => {
        try {
            await setDoc(doc(db, "Questions", newQuestionData.questionID), {
                ...newQuestionData
            })
        } catch (e) {
            console.error("Error creating new question", e)
        }
    }

    const updateQuestion = async (questionID) => {
        try {
            const questionDocRef = doc(db, "Questions", questionID);
            await setDoc(questionDocRef, {
                ...newQuestionData, questionID: questionID
            })
            await getUnreleasedQuestions();
        } catch (e) {
            console.error("Error updating question", e)
        }
    }

    const deleteQuestion = async (questionID) => {
        try {
            const questionRef = doc(db, "Questions", questionID)
            return await deleteDoc(questionRef)
        } catch (e) {
            console.error("Error deleting question", e)
        }
    }

    useEffect(() => {
        if (!Cookies.get("userID") && db) {
            const date2038millis = 2145916800000;
            const expiryDate = Math.floor((date2038millis - Date.now()) / 24 / 60 / 60 / 1000);
            const newUserID = generateUniqueID();
            Cookies.set("userID", newUserID, { expires: expiryDate });
            newUser(Cookies.get("userID")).then(() => {
                getUser(Cookies.get("userID"))
            });
        } else if (db) {
            getUser(Cookies.get("userID"))
        }
    }, [db])

    useEffect(() => {
        if (db && userData.userLoaded) {
            getQuestion(["0", ...userData.questionsCompleted]);
            getNextQuestionTime();
        }
    }, [db, userData.userLoaded])

    useEffect(() => {
        if (needUnreleasedQuestions) {
            getUnreleasedQuestions();
        }
    }, [needUnreleasedQuestions])

    useEffect(() => {
        if (needReleasedQuestions) {
            getReleasedQuestions()
        }
    }, [needReleasedQuestions])

    const getGuessNums = () => {
        let guessNums = [];
        userData.guessNums.forEach((item, i) => {
            guessNums.push(turnsGuessedCorrect[1] === i + 1 ? item + 1 : item)
        })
        // turnsGuessedCorrect[0] === i + 1 && turnsGuessedCorrect[1] === i + 1 ? item + 2 : turnsGuessedCorrect.includes(i + 1) ? item + 1 : item
        return guessNums;
    }

    const getWinRate = () => {
        let newGamesWon;
        let newGamesPlayed;
        let winRate;
        if (gameWon) {
            newGamesWon = userData.gamesWon + 1;
            newGamesPlayed = userData.gamesPlayed + 1;
        } else if (!gameWon) {
            newGamesWon = userData.gamesWon;
            newGamesPlayed = userData.gamesPlayed + 1;
        }
        winRate = (newGamesWon / newGamesPlayed) * 100;
        return Math.floor(winRate);
    }

    useEffect(() => {
        if (isGameOver && userData.userLoaded) {
            if (!userData.questionsCompleted.includes(questionData.questionID)) {   
                const newUserData = {
                gamesPlayed: userData.gamesPlayed + 1,
                gamesWon: gameWon ? userData.gamesWon + 1 : userData.gamesWon,
                gamesLost: !gameWon ? userData.gamesLost + 1 : userData.gamesLost,
                currentStreak: gameWon ? userData.currentStreak + 1 : 0,
                maxStreak: gameWon && userData.currentStreak + 1 > userData.maxStreak ? userData.currentStreak + 1 : userData.maxStreak,
                questionsCompleted: [ ...userData.questionsCompleted, questionData.questionID ],
                winRate: getWinRate(),
                userID: userData.userID,
                guessNums: getGuessNums(),
            }
            updateUser(Cookies.get("userID"), newUserData)
                .then(() => { getUser(Cookies.get("userID")) });
            }
        }
    }, [isGameOver])

    useEffect(() => {
        if (sendQuestion) {
            newQuestion();

            dispatch({
                type: "setSendQuestion",
                payload: {
                    sendQuestion: false
                }
            })
        }
    }, [sendQuestion])
    
    useEffect(() => {
        if (updatingQuestion) {
            updateQuestion(editQuestion.questionData.questionID);

            dispatch({
                type: "setUpdatingQuestion",
                payload: {
                    updatingQuestion: false
                }
            })
        }
    }, [updatingQuestion])

    useEffect(() => {
        if (deletingQuestion) {
            deleteQuestion(editQuestion.questionData.questionID);

            dispatch({
                type: "setDeletingQuestion",
                payload: {
                    deletingQuestion: false
                }
            })
        }
    }, [deletingQuestion])

    useEffect(() => {
        connectDB();
    }, [app])

    useEffect(() => {
        connectStorage();
    }, [app])

    return null;
}
  
export default GameDataService;
  