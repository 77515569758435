import { useState, useEffect } from "react";
import NewQuestionSidebar from "./NewQuestionSidebar/NewQuestionSidebar";
import NewQuestionPreview from "./NewQuestionPreview/NewQuestionPreview";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { useDispatch, useSelector } from "react-redux";

import classes from "./NewQuestion.module.css";

const NewQuestion = () => {
    const dispatch = useDispatch();

    const [imageFile, setImageFile] = useState("");
    const [imageUploaded, setImageUploaded] = useState(false);
    const newQuestionData = useSelector(state => state.newQuestionData);
    const editQuestion = useSelector(state => state.editQuestion);
    const app = useSelector(state => state.firebaseApp);

    const storage = getStorage(app);

    useEffect(() => {
        if (imageUploaded) {
            const imageRef = ref(storage, newQuestionData.questionID.toString());
            getDownloadURL(imageRef).then((url) => {
                dispatch({
                    type: "setImageUpdating",
                    payload: {
                        imageUpdating: true
                    }
                })
                dispatch({
                    type: "setNewImgURL",
                    payload: {
                        imgURL: url
                    }
                })
                setImageUploaded(false)
            })
        }
    }, [imageUploaded])

    useEffect(() => {
        if (imageFile) {
            const storageRef = ref(storage, newQuestionData.questionID.toString());
            uploadBytes(storageRef, imageFile).then((snapshot) => {
                setImageUploaded(true)
            });
        }
    }, [imageFile])

    useEffect(() => {
        if (!editQuestion.isEditing) {
            dispatch({
                type: "setNewGridPosition",
                payload: { 
                    gridPosition: {
                        x: 0,
                        y: 0
                    }
                }
            })
        }
    }, [editQuestion.isEditing])

    return (
        <div className={classes.NewQuestion}>
            <NewQuestionSidebar imageFile={imageFile} setImageFile={setImageFile}/>
            <NewQuestionPreview />
        </div>
    );
}
  
export default NewQuestion;
  