import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import classes from "./ToolbarBtn.module.css"
import { getAuth, signOut } from "firebase/auth";

const ToolbarBtn = (props) => {
    const dispatch = useDispatch();
    const activeToolbarBtn = useSelector(state => state.activeToolbarBtn);
    const screenSize = useSelector(state => state.screenSize);

    const setBtnClasses = () => {
        let classString = `${classes.ToolbarBtn} ${classes.ToolbarBtnLarge}`;

        classString = classString.concat(` ${classes.ToolbarBtnBlack}`)
        if (activeToolbarBtn === props.name.toLowerCase()) {
            classString = classString.concat(` ${classes.ToolbarBtnActive}`)
        }
        
        return classString;
    }

    let auth;

    if (props.name === "Log out") {
        auth = getAuth();
    }

    return (
                <Link to={props.linkTo}>
                    <button 
                    onClick={() => {
                        dispatch({
                            type: "setActiveToolbarBtn",
                            payload: { 
                                activeToolbarBtn: props.name.toLowerCase()
                            }
                        })
                        if (props.name === "Log out") {
                            signOut(auth).then(() => {
                                console.log("Signed out successfully")
                            }).catch((e) => {
                                console.error("Error signing out", e)
                            })

                            dispatch({
                                type: "setIsLoggedIn",
                                payload: {
                                    loggedIn: false
                                }
                            })
                        }
                    }}
                    className=
                        {
                            setBtnClasses()
                        }>
                            {props.name}
                    </button>
                </Link>
    )
}

export default ToolbarBtn;